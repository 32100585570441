import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    paddingRight: '20px',
    paddingTop: 0,
    paddingBottom: 0
  }));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    padding: 2,
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

export const TableVisualization = ({rows, columns, title, label}) => {
  return (
    <div style={{overflowX: 'hidden', overflowY: 'scroll', maxHeight: '350px'}}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{paddingTop: 0, paddingBottom: 0}}>{label}</TableCell>
            {columns.sort().map(value => <TableCell align="center" sx={{paddingTop: 0, paddingBottom: 0}}>{value}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.sort((a, b) => b.value - a.value).map((row) => (
            <StyledTableRow
              key={row.label}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <StyledTableCell component="th" scope="row">
                {row.label}
              </StyledTableCell>
              {columns.length ? columns.map(metric => <TableCell align="center" sx={{paddingTop: 0, paddingBottom: 0}}>{row[metric] && row[metric].toLocaleString() || '0'}</TableCell>) : <TableCell align="center" sx={{paddingTop: 0, paddingBottom: 0}}>{row.value || '0'}</TableCell>}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}
