import { CombineBy } from './properties'
import { FilterBy } from './properties'

function getFilterQuery(properties: any, excludeKeys: string[], inverseKeys: string[]): any[] {
  let filterQueries = []

  function isObject(value) {
    const type = typeof value
    return value != null && (type === 'object' || type === 'function')
  }

  for (const [key, value] of Object.entries(properties)) {
    if (isObject(value) && !excludeKeys.includes(key)) {
      let filterQuery
      if (value.hasOwnProperty('filterQuery') && value['filterQuery']) {
        filterQuery = JSON.parse(JSON.stringify(value['filterQuery']))
        if(inverseKeys?.includes(key)) {
          const stateIndex = filterQuery['properties'].findIndex(el => el.column === 'state')
          const zipIndex = filterQuery['properties'].findIndex(el => el.column === 'zip_code')
          const changeIndex = stateIndex + zipIndex + 1
          if(changeIndex > -1) {
            const opToChange = filterQuery['properties'][changeIndex]['op']
            filterQuery['properties'][changeIndex]['op'] = opToChange === FilterBy.IN ? FilterBy.N_IN : opToChange === FilterBy.LIKE_IN ? FilterBy.LIKE_NOT_IN : opToChange
          }
        }
        filterQueries.push(filterQuery)
      }
      filterQueries.push(...getFilterQuery(value, excludeKeys, inverseKeys))
    }
  }

  return filterQueries
}

export const combineFilters = (properties, excludeKeys, inverseKeys) => {
  return {
    properties: getFilterQuery(properties, excludeKeys, inverseKeys),
    operation: CombineBy.AND,
  }
}

export function buildFilterQuery(
  properties: any,
  excludeKeys = [],
  encode = true,
  inverseKeys = [],
): string | object {
  const filterObject = combineFilters(properties, excludeKeys, inverseKeys)
  return encode ? btoa(JSON.stringify(filterObject)) : filterObject
}
