import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'Utils/store'
import { multiSelectSlice } from 'Slices/multiSelectSlice'
import CurrentlyEnrolledConfig from 'Configs/fields/currently_enrolled.json'
import { ContractSource } from 'Types/contractTypes'

const contractSource = ContractSource.candidates

const transformValues = function (selectedValues) {
  return selectedValues.map((value) => (value === 'None Selected' ? '' : value))
}

export const CurrentlyEnrolledSlice = multiSelectSlice(
  'currently_enrolled',
  'setCurrentlyEnrolled',
  CurrentlyEnrolledConfig.values,
  CurrentlyEnrolledConfig.meta,
  transformValues
)

export const selectCurrentlyEnrolled = createSelector(
  ({ onDemandFilter: { prospectProfile } }: RootState) =>
    prospectProfile.currentlyEnrolled.selectedValues,
  (
    selectedValues: string[]
  ): {
    selectedValues: string[]
    values: string[]
    audienceTypes: string[]
    label: string
  } => {
    return {
      selectedValues,
      values: CurrentlyEnrolledConfig.values,
      audienceTypes: CurrentlyEnrolledConfig.source[contractSource].audience_types,
      label: CurrentlyEnrolledConfig.meta.label,
    }
  }
)

export const CurrentlyEnrolledAudienceTypes = CurrentlyEnrolledConfig.source[contractSource].audience_types

export const { setCurrentlyEnrolled } = CurrentlyEnrolledSlice.actions

export default CurrentlyEnrolledSlice.reducer
