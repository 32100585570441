import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { styled } from '@mui/material/styles'
import {
  selectContractConfiguration,
  setContractConfiguration,
} from 'Slices/contracts/ContractConfigurationSlice'
import { DialogField } from 'Components/shared/dialog'
import { Input } from 'Components/shared/input'
import { Permissions } from 'Configs/userPermissions'
import { useHasPermission } from 'Hooks/useHasPermission'
import { ContractFormProps } from './types'
import { selectContractState } from 'Slices/contracts/ContractStateSlice'
import { checkIsInquiries } from 'Utils/urlUtils'

const ContractValue = styled('div')(({ theme, disabledContract }) => ({
  color: disabledContract
    ? theme.palette.primary.dark
    : theme.palette.secondary['100'],
  fontSize: '0.875rem',
  lineHeight: '21px',
}))

export const TimeBasedConfigurationFields = ({ control }: ContractFormProps) => {
  const theme = useTheme()
  const hasMdBreakpoint = useMediaQuery(theme.breakpoints.up('md'))
  const dispatch = useDispatch()

  const {
    contractType,
    contractSource,
    isDisabledContract,
    leadCap: contractLeadCap,
    salesforceID,
  } = useSelector(selectContractConfiguration)
  const { fieldsAvailableForContract } =
    useSelector(selectContractState)

  const { hasPermission: canUpdateOrCreateContractConfig } = useHasPermission(
    Permissions.UpdateContractConfiguration
  )
  const disabledEditing =
    isDisabledContract ||
    !canUpdateOrCreateContractConfig ||
    !contractSource ||
    !contractType

  return (
    <>
        {fieldsAvailableForContract.includes('salesforce_id') && (
          <DialogField id='salesforceID' label={`${checkIsInquiries() ? '*' : ''}Salesforce ID`}>
            {disabledEditing ? (
              <ContractValue>{salesforceID}</ContractValue>
            ) : (
              <Input
                id='add-salesforce-id'
                placeholder='Add Salesforce ID'
                name='salesforceID'
                value={salesforceID}
                style={hasMdBreakpoint ? { width: '401px' } : {}}
                control={control}
                rules={{
                  pattern: {
                    value: /^[a-zA-Z0-9]+$/,
                    message:
                      'Salesforce ID should contain only letters and numbers',
                  },
                  minLength: {
                    value: 11,
                    message:
                      'Salesforce ID should have a minimum length of 11 characters',
                  },
                  maxLength: {
                    value: 18,
                    message: 'Salesforce ID should have a maximum length of 18',
                  },
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  dispatch(
                    setContractConfiguration({ salesforceID: e.target.value })
                  )
                }}
                disabled={disabledEditing}
              />
            )}
          </DialogField>
        )}
        {fieldsAvailableForContract.includes('lead_cap') && (
          <DialogField id='contract-lead-cap' label='*Contract Lead Cap'>
            {disabledEditing ? (
              <ContractValue disabledContract={disabledEditing}>
                {contractLeadCap ? String(contractLeadCap) : ''}
              </ContractValue>
            ) : (
              <Input
                id='update-contract-lead-cap'
                value={contractLeadCap ? String(contractLeadCap) : ''}
                name='leadCap'
                control={control}
                rules={{
                  required: 'Contract Lead Cap is a required field',
                }}
                placeholder='0'
                style={hasMdBreakpoint ? { width: '229px' } : {}}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch(
                    setContractConfiguration({
                      leadCap: Number(e.target.value),
                    })
                  )
                }
              />
            )}
          </DialogField>
        )}
        </>
  )
}
