export const isInquiries =
  window.location.pathname.includes('inquiries') ||
  window.location.hash.includes('inquiries')
export const isCandidates =
  window.location.pathname.includes('candidates') ||
  window.location.hash.includes('candidates')
export const getContractSource = () => {
  if (isInquiries) {
    return 'inquiries'
  }
  if (isCandidates) {
    return 'candidates'
  }
  return null
}

export const checkIsInquiries = () => {
  return window.location.pathname.includes('inquiries') ||
  window.location.hash.includes('inquiries')
}