import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { alpha } from '@mui/material'
import Button from '@mui/material/Button'
import { ContractType, ContractSource, LookBackPeriod } from 'Types/index'
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { useCreateUpdateContract } from 'Hooks/contract'
import { useGetContractByIdQuery } from 'Services/contracts/Contracts'
import { useGetContractConfigQuery } from 'Services/global/contractConfig'
import {
  Body,
  BodyActions,
  BodyHeader,
  ContractHeaderLabel,
} from 'Components/shared/body'
import {
  setContractConfiguration,
  convertContractToContractConfiguration,
  selectContractConfiguration,
  CreateNewDefaultFieldValues,
  selectContractState,
  setContractState,
  resetContractStates,
  deliveryModelValueToKey,
} from 'Slices/contracts'
import { Loading } from 'Utils/loading'
import {
  ContractConfiguration,
  ContractDeliveryCriteria,
  ContractDeduplicates,
  ContractDetails,
} from 'Components/connectedComponents/contracts'
import { SegmentsList } from 'Components/connectedComponents/segments'
import { useAppDispatch } from 'Utils/store'

const StyledButton = styled(Button)(({ theme }) => ({
  border: `1.8px solid ${theme.palette.primary.main}`,
  borderRadius: '4px',
  fontSize: '0.875rem',
  fontWeight: theme.typography.fontWeightMedium,
  height: '40px',
  lineHeight: '18px',
  minWidth: 'calc(100% - 40px)',
  [theme.breakpoints.up('md')]: {
    minWidth: 'auto',
  },
}))

const CancelButton = styled(StyledButton)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.primary.main,
  lineHeight: '20px',
  '&:hover': {
    backgroundColor: `${alpha(theme.palette.primary.light, 0.4)}`,
  },
  [theme.breakpoints.up('md')]: {
    width: '110px',
  },
}))

const AddButton = styled(StyledButton)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  boxShadow: `0px 4px 20px ${alpha(theme.palette.common.white, 0.4)}`,
  '&:hover': {
    backgroundColor: `${alpha(theme.palette.primary.main, 0.85)}`,
  },
  '&.Mui-disabled': {
    color: theme.palette.common.white,
    backgroundColor: 'hsla(216, 79%, 72%, 1)',
    border: 'none',
  },
  [theme.breakpoints.up('md')]: {
    width: '104px',
  },
}))

interface ContractFormProps {
  contractSource: ContractSource
  contractType: ContractType
}

export const ContractForm = ({
  contractSource,
  contractType: contractTypeFromProps,
}: ContractFormProps) => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    // handle unmount
    return () => {
      dispatch(resetContractStates())
    }
  }, [dispatch])

  const fullParamsObject = useParams()
  const { contractId: contractIdFromparam, ipedId } = fullParamsObject
  const isNewContract =
    contractIdFromparam === undefined || contractIdFromparam === 'new'
  const navigate = useNavigate()
  const { audienceType, leadCap, segmentLeadCapTotal, contractType } = useSelector(
    selectContractConfiguration
  )
  const { isDuplicating, sectionsAvailablForContract } =
    useSelector(selectContractState)

  const currentContractId =
    isNewContract || isDuplicating ? null : contractIdFromparam

  /** Get Contract Information */
  const { data: currentContract, isLoading: isLoadingCurrentContract } =
    useGetContractByIdQuery(
      {
        ipedId,
        contractId: currentContractId,
      },
      { skip: currentContractId === null }
    )
  const { data: contractConfig, isLoading: isLoadingContractConfig } =
    useGetContractConfigQuery(
      { contractSource, contractType: contractType ? contractType : contractTypeFromProps, audienceType },
      { skip: !contractSource || !audienceType }
    )
  const [
    mutateContractFromState,
    {
      loading: contractCRUDLoading,
      error,
      data: {
        contractId: CreatedOrUpdatedID,
        institutionId: contractCRUDInstitutionId,
      },
    },
  ] = useCreateUpdateContract()

  useEffect(() => {
    if (currentContract) {
      dispatch(
        setContractConfiguration(
          convertContractToContractConfiguration(currentContract)
        )
      )
    }
  }, [currentContract])

  useEffect(() => {
    // TODO move all to feature flags
    const isInquiries = contractSource == ContractSource.inquiries
    if ((contractType || contractTypeFromProps) && contractSource && contractConfig) {
      dispatch(
        setContractState({
          fieldsAvailableForContract: contractConfig.contract_components,
          sectionsAvailablForContract: contractConfig.sections_available,
        })
      )
      if (isNewContract) {
        dispatch(
          setContractConfiguration({
            selectedLookbackPeriod: LookBackPeriod.toNumberOfDays(
              contractConfig?.default_lookback_period
            ),
            selectedFields: contractConfig?.fields_to_export,
            isClean: true,
            contractType: contractType ? contractType : contractTypeFromProps,
          })
        )
      }
      dispatch(setContractState({ canDuplicateContract: true }))
    }
  }, [contractType, contractSource, contractConfig, isNewContract, contractTypeFromProps])

  const {
    control,
    formState: { errors: formErrors, isValid },
    setError: setFormError,
    clearErrors: clearFormErrors,
    handleSubmit,
    resetField,
    reset,
  } = useForm({
    mode: 'onChange',
  })

  useEffect(() => {
    if (isDuplicating) {
      dispatch(
        setContractConfiguration({
          contractId: '',
          salesforceID: '',
          contractInstitutionId: ipedId,
          isDisabledContract: false,
          leadCap: null,
          startDate: '',
          endDate: '',
          notes: ''
        })
      )
      clearFormErrors()
    } else if (currentContract) {
      dispatch(
        setContractConfiguration(
          convertContractToContractConfiguration(currentContract)
        )
      )
    } else if (isNewContract) {
      dispatch(
        setContractConfiguration({
          contractSource,
          contractType,
          contractInstitutionId: ipedId,
          selectedDeliveryCadence:
            CreateNewDefaultFieldValues[contractSource]
              ?.selectedDeliveryCadence || null,
          selectedDeliveryModel: deliveryModelValueToKey(
            CreateNewDefaultFieldValues[contractSource]
              ?.selectedDeliveryModel || null
          ),
          isClean: true,
        })
      )
    }
  }, [isNewContract, isDuplicating])

  const navigateToContractPage = (contractId?: string) => {
    dispatch(resetContractStates())
    if (contractId) {
      navigate(
        `/institution/${ipedId}/${contractSource}/contracts/${contractId}`
      )
    } else {
      navigate(`/institution/${ipedId}/${contractSource}`)
    }
  }

  useEffect(() => {
    if (CreatedOrUpdatedID && contractCRUDInstitutionId) {
      if (isNewContract || isDuplicating) {
        navigateToContractPage(isDuplicating ? CreatedOrUpdatedID : null)
      }
    }
  }, [CreatedOrUpdatedID, contractCRUDInstitutionId])

  useEffect(() => {
    if (!isNewContract && leadCap < segmentLeadCapTotal) {
      setFormError(
        'root.leadCap',
        {
          type: 'manual',
          message: 'lead cap lower than segment total',
        },

        { shouldFocus: true }
      )
    } else {
      clearFormErrors('root.leadCap')
    }
  }, [leadCap, segmentLeadCapTotal])

  const disableFormSubmit = !isValid || Object.keys(formErrors).length > 0

  const createOrUpdateContractFromState = () => {
    mutateContractFromState(ipedId)
  }

  if (
    isLoadingCurrentContract ||
    isLoadingContractConfig ||
    contractCRUDLoading
  ) {
    return <Loading />
  }

  return currentContract || isNewContract ? (
    <Body>
      {!isNewContract && (
        <ContractDetails
          control={control}
          submitForm={handleSubmit(createOrUpdateContractFromState)}
        />
      )}
        {isNewContract && (
          <BodyHeader>
            <ContractHeaderLabel label={`Add Contract`} />
            <BodyActions>
              <CancelButton onClick={()=>{navigateToContractPage()}}>Cancel</CancelButton>
              <AddButton
                disabled={disableFormSubmit}
                onClick={handleSubmit(createOrUpdateContractFromState)}
              >
                Add
              </AddButton>
            </BodyActions>
          </BodyHeader>
        )}

        {currentContract && !isDuplicating && <SegmentsList />}
      <ContractConfiguration control={control} />
      <ContractDeliveryCriteria control={control} />
      {sectionsAvailablForContract.includes('deduplicates') && (
        <ContractDeduplicates control={control} />
      )}
    </Body>
  ) : null
}
