import React from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Sidebar } from 'Containers/shared/sidebar'
import { HeaderLabel } from 'Components/shared/sidebar'
import { styled } from '@mui/material/styles'


import {
  Body,
  BodyTitle,
  BodyHeader,
  BodyHeaderUI,
  BodyContent
} from 'Components/shared/body'
import { Loading } from 'Utils/loading'
import { useToggle } from 'Hooks/useToggle'

import { useGetAudienceTypesQuery } from 'Services/global/audienceTypes'
import { useHasPermission } from 'Hooks/useHasPermission'
import { ContractSource, SegmentAction} from 'Types/index'
import { OnDemandExploreSegmentsFilter } from 'Containers/shared/segmentfilters'
import { Permissions } from 'Configs/userPermissions'
import { StudentVisualization, TableVisualization } from './'
import { getTooltipHasData } from '@mui/x-charts/ChartsTooltip/utils'
import { selectVisualizationData } from 'Slices/websocketSlice'

const VizWrap = styled('div')(({ theme, isSkinny }) => ({
  display: 'grid',
  gridTemplateColumns: isSkinny ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)',
  gap: '1rem',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
  h2: {
    fontSize: '1.2rem',
  }
}))

const SideBarShim = styled('div')(({ theme, isOpen }) => {return ({
  position: 'relative',
  left: isOpen ? '390px' : '0',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '1rem',
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  h2: {
    fontSize: '1.2rem',
  },
  [BodyHeaderUI]: {
    width: isOpen ? 'calc(100% - 375px)' : '100%',
  }
})})

export const Explore = () => {
    const query = new URLSearchParams(useLocation().search)
    const {charts, students} = useSelector(({websocketStatus}) => ({charts: websocketStatus.charts, students: websocketStatus.availableStudents}))
    const {charts: oppportunities, students: additionalStudents} = useSelector(({websocketStatus}) => ({charts: websocketStatus.charts, students: websocketStatus.additionalStudents}))

    const segmentAction = query.get('action') as SegmentAction
    const [showSidebar, setShowSidebar] = useToggle(false)
  
    const { data: audienceTypes, isFetching: isFetchingAudienceTypes } =
      useGetAudienceTypesQuery(ContractSource.candidates)

    const { hasPermission: canExploreSegments, isFetching } = useHasPermission(
      Permissions.ViewEnhancedCandidatesExploreSegments
    )

    const studentsCount = students && (Array.isArray(students) ? students?.reduce((acc, grouping) => grouping.count + acc, 0,) : students) || 0
    const additionalCount = additionalStudents && (Array.isArray(additionalStudents) ? additionalStudents?.reduce((acc, grouping) => grouping.count + acc, 0,) : additionalStudents) || 0

    const {studentsVisualizedForChart} = useSelector(({websocketStatus}) => selectVisualizationData(websocketStatus?.auxillaryCharts?.enhancedComparison, websocketStatus.availableStudents))


  if (isFetching || isFetchingAudienceTypes) return <Loading />
  return (
    <Body>
      <Sidebar
        sidebarTitle={[SegmentAction.explore]}
        header={<HeaderLabel label='Explore Segments' />}
        showSidebar={showSidebar}
        setShowSideBar={setShowSidebar}
        handleCloseSidebar={setShowSidebar}
      >
         <OnDemandExploreSegmentsFilter 
         segmentAction={segmentAction}
         close={() => setShowSidebar(false)}
         />
      </Sidebar>
      <SideBarShim isOpen={showSidebar}>
      <BodyHeader sx={{width: 'calc(100% - 524px)'}}>
        <BodyTitle>{studentsCount.toLocaleString()} Students Available</BodyTitle>
        <div>
          Enhanced Comparison
          <TableVisualization rows={studentsVisualizedForChart} columns={[]} title={""} label={""} />
        </div>
      </BodyHeader>
      <BodyContent>
        <VizWrap isSkinny={showSidebar} >
          {charts.map(({id, title}) => (
            <div>
              <h2>{title}</h2>
              <StudentVisualization chartId={id} dataSet={students}/>
            </div>
          ))}
        </VizWrap>
        {additionalCount > 0 && (<div style={{"marginTop": "90px"}}><BodyTitle>{additionalCount.toLocaleString()} Additional Opportunities</BodyTitle>
        <VizWrap>
          {oppportunities?.map(({id, title}) => (
            <div>
              <h2>{title}</h2>
              <StudentVisualization chartId={id} dataSet={additionalStudents} secondary/>
            </div>
          ))}
        </VizWrap></div>)}
        
      </BodyContent>
      </SideBarShim>
    </Body>
  )
}