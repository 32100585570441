import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'Utils/store'
import { multiSelectSlice } from 'Slices/multiSelectSlice'
import SliceConfig from 'Configs/fields/utm_source.json'
import { ContractSource } from 'Types/contractTypes'
import { CandidateContractsApi } from 'Services/contracts/candidatesContracts'

const contractSource = ContractSource.candidates

const transformValues = function (selectedValues) {
  return selectedValues.map((value) => (value === 'None Selected' ? '' : value))
}

const extraReducer = (builder) => {
  builder.addMatcher(
    CandidateContractsApi.endpoints.getUTMSource.matchFulfilled,
    (state, action) => {
      state.values = action.payload.data.map((item) => item.utm_source).filter(val => !!val)
    }
  )
}

export const UTMSourceSlice = multiSelectSlice(
  'utm_source',
  'setUTMSource',
  SliceConfig.values,
  SliceConfig.meta,
  transformValues,
  null,
  extraReducer
)

export const selectUTMSource = createSelector(
  ({ onDemandFilter: { prospectProfile } }: RootState) => prospectProfile.UTMSource.selectedValues,
  ({ onDemandFilter: { prospectProfile } }: RootState) => prospectProfile.UTMSource.values,
  (
    selectedValues: string[],
    values: string[]
  ): {
    selectedValues: string[]
    values: string[]
    audienceTypes: string[]
    label
  } => {
    return {
      selectedValues,
      values,
      audienceTypes: SliceConfig.source[contractSource].audience_types,
      label: SliceConfig.meta.label,
    }
  }
)

export const { setUTMSource } = UTMSourceSlice.actions

export default UTMSourceSlice.reducer