import React from 'react'
import { styled } from '@mui/material/styles'
import { useLocation } from 'react-router-dom'


const Message = styled('span')(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: '0.75rem',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '15px',
  padding: '13px 30px'
}))

export const TableFooterText = () => {
  const { pathname } = useLocation()
  const urlArray = pathname.split('/')
  const downloadType = urlArray[urlArray.length - 2]
  const capitalizedDownloadType = downloadType?.charAt(0).toUpperCase() + downloadType?.slice(1)
  
  return (
    <Message>{capitalizedDownloadType} downloads are only kept for 180 days. After this time, files will not be able to download.</Message>
  )
}