import * as React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete'
import useMediaQuery from '@mui/material/useMediaQuery'
import ListSubheader from '@mui/material/ListSubheader'
import { SidebarField } from 'Components/shared/sidebar'
import { TextButton } from 'Components/shared/buttons'
import Popper from '@mui/material/Popper'
import { useTheme, styled } from '@mui/material/styles'
import { VariableSizeList, ListChildComponentProps } from 'react-window'
import Checkbox from '@mui/material/Checkbox'
import CheckboxUnchecked from 'Assets/images/checkbox_unchecked.svg'
import CheckboxChecked from 'Assets/images/checkbox_checked.svg'

const StyledInput = styled(TextField)(({ theme }) => ({
  width: '200px',
  height: '32px',
  '& .MuiOutlinedInput-root': {
    padding: 0,
    paddingLeft: '10px',
    border: `1.5px solid ${theme.palette.primary.light}`,
    color: theme.palette.primary.main,
    fontSize: '0.75rem',
    fontWeight: theme.typography.fontWeightMedium,
    borderRadius: '4px',
  },
  '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
    minWidth: '5px'
  },
  '& .MuiSvgIcon-root': {
    '& path': {
      fill: theme.palette.primary.main,
    },
  },
}))

const LISTBOX_PADDING = 16 // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props

  const dataSet = data[index]

  const inlineStyle = {
    ...style,
    // top: (style.top as number) + LISTBOX_PADDING,
    top: style.top as number,
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '15px',
    background: 'none',
    height: 'auto',
    width: 'auto',
    color: dataSet[0]?.['aria-selected'] ? 'hsla(216, 93%, 46%, 1)' : '#6373A4',
    paddingLeft: '0px',
    whiteSpace: 'nowrap',
    // overflow: 'hidden',
    // textOverflow: 'ellipsis',
    // width: '98%',
    display: 'inline-block',
  }

  if (dataSet.hasOwnProperty('group')) {
    return (
      <ListSubheader key={dataSet.key} component='div' style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    )
  }

  return (
    <li {...dataSet[0]} style={inlineStyle}>
      {dataSet[1]}
    </li>
  )
}

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null)
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])
  return ref
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props
  const itemData: React.ReactChild[] = []
  ;(children as React.ReactChild[]).forEach(
    (item: React.ReactChild & { children?: React.ReactChild[] }) => {
      itemData.push(item)
      itemData.push(...(item.children || []))
    }
  )

  //This will make sure that selected options are at the top
  itemData.sort((a, b) => b[0]?.['aria-selected'] - a[0]?.['aria-selected'])

  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  })
  const itemCount = itemData.length
  const itemSize = smUp ? 36 : 48

  const getChildSize = (child: React.ReactChild) => {
    if (child.hasOwnProperty('group')) {
      return 48
    }

    return itemSize
  }

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
  }

  const gridRef = useResetCache(itemCount)

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          // height={getHeight()}
          width='100%'
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType='ul'
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
})

interface Props {
  /** label is the label of the dropdown */
  label?: string
  /** counterLabel is the label used in the counter */
  counterLabel?: string
  /** initialSelectedValues is the array of values that are selected in the dropdown */
  initialSelectedValues?: string[]
  /** values is the array of values to be displayed in the dropdown */
  values: string[]
  /** selectedValues is the array of values that are selected in the dropdown */
  selectedValues: string[]
  /** onChange is the function to be called on change of the dropdown */
  setSelectedValues: (values: string[]) => void
  /** disabled is the boolean to disable the dropdown */
  disabled?: boolean
}

const AutoComplete = ({
  values,
  counterLabel,
  initialSelectedValues,
  selectedValues,
  setSelectedValues,
  label,
  disabled,
  ...props
}) => {
  return (
    <Autocomplete
      multiple
      id='virtualize-multiselect'
      sx={{ width: 200 }}
      disableListWrap
      disableCloseOnSelect
      disableClearable
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      options={values}
      value={initialSelectedValues || []}
      onChange={(_, checkedValues) => {
        setSelectedValues(checkedValues)
      }}
      renderInput={(params) => {
        const { InputProps, ...restParams } = params
        const { startAdornment, ...restInputProps } = InputProps
        const selected = startAdornment?.['length']
        return (
          <StyledInput
            {...restParams}
            placeholder={!selected ? `Search ${label}` : ''}
            InputProps={{
              ...restInputProps,
              startAdornment: (
                <div>
                  {selected && selected > 0 && `${selected} ${counterLabel}`}
                </div>
              ),
            }}
            onKeyDown={(event: any) => {
              if (event.key === 'Backspace') {
                event.stopPropagation()
              }
            }}
          />
        )
      }}
      renderOption={(props, option, { selected }) => {
        return [
          {
            ...props,
            'aria-disabled': disabled ? disabled : props['aria-disabled'],
          },
          (
            <React.Fragment>
              <Checkbox
                icon={<CheckboxUnchecked />}
                checkedIcon={<CheckboxChecked />}
                style={{ marginRight: 8 }}
                checked={selected}
                disabled={disabled}
              />
              {option as any}
            </React.Fragment>
          ) as any,
        ]
      }}
    />
  )
}

function LabelWrap({children, label}) {
  return label ? (
    <SidebarField id={`label-${label}`} label={label}>
      {children}
    </SidebarField>
  ) : (
    children
  )
}

export function VirtualizedMultiSelect({
  values,
  counterLabel,
  initialSelectedValues,
  selectedValues,
  setSelectedValues,
  label,
  disabled,
  ...props
}: Props) {
  const valuesWithoutAny = values.filter(value => value !== 'Any' && value !== 'any')

  return (
    <LabelWrap label={label}>
      <AutoComplete
        values={values}
        counterLabel={counterLabel}
        disabled={disabled}
        initialSelectedValues={initialSelectedValues}
        selectedValues={selectedValues}
        setSelectedValues={setSelectedValues}
        label={label}
        {...props}
      />
      <TextButton
        onClick={() => setSelectedValues(valuesWithoutAny)}
        label={'Select All'}
        disabled={JSON.stringify(selectedValues) === JSON.stringify(valuesWithoutAny)}
      />
    </LabelWrap>
  )
}
