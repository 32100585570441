import React, {useState,} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import { default as MUITable } from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Sort from 'Assets/images/sort.svg'
import Checkbox from '@mui/material/Checkbox'
import CheckboxUnchecked from 'Assets/images/checkbox_unchecked.svg'
import CheckboxChecked from 'Assets/images/checkbox_checked.svg'

//sortable imports
import {
  DndContext, 
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

import {setContractConfiguration} from 'Slices/contracts/ContractConfigurationSlice'
import {
  selectContractConfiguration,
} from 'Slices/contracts'

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  background: 'transparent',
  '& th': {
    border: 0,
    color: theme.palette.primary.dark,
    fontSize: '0.75rem',
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: '0.01em',
    lineHeight: '16px',
    padding: '2px 0px 11px 32px',
  },
}))

const StyledTableSortHead = styled(TableSortLabel)(({ theme }) => ({
  background: 'transparent',
  '&.Mui-active': {
    border: 0,
    color: theme.palette.primary.dark,
    fontSize: '0.75rem',
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: '0.01em',
    lineHeight: '16px',
  },
}))

const StyledTableBody = styled(TableBody)(({ theme, disabled }) => ({
  background: 'white',
  '& td': {
    borderBottom: `1px solid ${theme.palette.grey['200']}`,
    color: disabled
      ? theme.palette.primary.dark
      : theme.palette.secondary['100'],
    fontSize: '0.875rem',
    fontWeight: theme.typography.fontWeightMedium,
    height: '18px',
    lineHeight: '16px',
    paddingLeft: '32px',
    paddingTop: '19.75px',
    paddingBottom: '19.75px',
  },
}))

const StyledTableCell = styled(TableCell)(({ width }) => ({
  maxWidth: width,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}))

const StyledTableRow = styled(TableRow)(({ theme, onClick, variant }) => ({
  height: '68px',
  '&:hover': {
    cursor: onClick ? 'pointer' : 'auto',
  },
  '&:nth-of-type(odd)': {
    backgroundColor:
      variant === 'striped'
        ? theme.palette.background.main
        : theme.palette.common.white,
  },
  '&:last-child': {
    '& td': {
      borderBottom:
        variant === 'striped'
          ? theme.palette.common.white
          : `1px solid ${theme.palette.grey['200']}`,
    },
  },
}))

interface TableProps {
  width?: string
  height?: string
  maxHeight?: string
  sortable?: boolean
  columnData: {
    /**  columnName is the name of the column */
    columnName: string
    /**  columnWidth is the width of the column */
    columnWidth?: number
    /** sortOrder can be 'ASC', 'DESC', or 'NONE', make this an ENUM */
    sortOrder?: string
    /** Sort by this column */
    sortBy?: string
  }[]
  /** rows is an array of arrays that contain the data for each row */
  rows: any[]
  /** handleRowClick is a function that is called when a row is clicked */
  handleRowClick?: (rowIdx: number) => void
  /** variant can be 'default' or 'striped' */
  variant?: 'default' | 'striped'
  /** setSortOrder is a function that is called when a column is clicked */
  setSortOrder?: (sortOrder: string) => void
  /** setSortBy is a function that is called when a column is clicked */
  setSortBy?: (sortBy: string) => void
  /** selectable determines if rows are selectable using a checkbox*/
  isSelectable?: boolean
  /** selectedAll determines if all rows are selected */
  selectedAll?: boolean
  /** handleAllClick is a function that is called when the select all checkbox is clicked */
  handleAllClick?: (flag: boolean) => void
  /** handleCheckboxClick is a function called when a checkbox is clicked*/
  handleCheckboxClick?: any
  /** rowIndexes is an array of booleans that determines if a row is selected */
  rowIndexes?: boolean[]
  /** boolean that determines text color if contract is expired */
  disabled?: boolean
  /** boolean that determines if table has overflow */
  tableOverflow?: boolean
  /** renderTableCell is a function that is called when a cell is rendered */
  renderTableCell?: ({ idx, row, columnData }) => React.ReactNode
  /* renderTableRow is a function that is called when a row is rendered */
  renderTableRow?: ({
    handleRowClick,
    handleCheckboxClick,
    row,
    idx,
    disabled,
    variant,
    rowIndexes,
    isSelectable,
    renderTableCell,
    columnData,
  }) => React.ReactNode
}

export const CustomTableCell = ({ idx, row, columnData, cellStyle = {} }) => {
  return (
    <StyledTableCell
      sx={cellStyle}
      align='left'
      width={columnData[idx - 1]?.columnWidth}
    >
      {row[idx]}
    </StyledTableCell>
  )
}

export const SortableCustomTableCell = ({ idx, row, columnData, cellStyle = {}, setNodeRef, listeners, }) => {
  return (
    <StyledTableCell
      ref={setNodeRef}
      sx={cellStyle}
      align='left'
      width={columnData[idx - 1]?.columnWidth}
      {...listeners}
    >
      {row[idx]}
    </StyledTableCell>
  )
}

export const CustomTableRow = ({
  handleRowClick,
  handleCheckboxClick,
  row,
  id,
  idx,
  disabled,
  variant,
  rowIndexes,
  isSelectable,
  renderTableCell,
  columnData,
  rowStyle = {},
  sortable = false,
  rowsLength = -1
}) => {

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = sortable && row[1] !== 'Total' ? useSortable({id}) : {attributes: {}, listeners: {}, setNodeRef: null, transform: null, transition: {}};
  const style = {
    transform: transform && CSS.Transform.toString(transform),
    transition: transition
  };
  return (
    <StyledTableRow
    style={style}
      sx={rowStyle}
      key={row}
      onClick={
        handleRowClick && !disabled ? () => handleRowClick(idx, id) : undefined
      }
      hover={handleRowClick ? true : false}
      variant={variant}
      {...attributes} 
    >
      {isSelectable && (
        <TableCell align='left' width={40}>
          <Checkbox
            key={rowIndexes[idx] ? `checked-${idx}` : `unchecked-${idx}`}
            icon={<CheckboxUnchecked />}
            checkedIcon={<CheckboxChecked />}
            checked={rowIndexes[idx]}
            disabled={disabled}
            onClick={(e) => handleCheckboxClick(e, idx)}
          />
        </TableCell>
      )}
      {row?.map((_, colIdx) => {
        return (
          colIdx !== 0 &&
          (renderTableCell ? (
            renderTableCell({ idx: colIdx, row, columnData })
          ) : sortable && colIdx === 1 && idx !== rowsLength - 1 ? (<SortableCustomTableCell
            setNodeRef={setNodeRef} 
            key={colIdx}
            idx={colIdx}
            row={row}
            columnData={columnData}
            listeners={listeners}
          />) : (
            <CustomTableCell
              key={colIdx}
              idx={colIdx}
              row={row}
              columnData={columnData}
            />
          ))
        )
      })}
    </StyledTableRow>
  )
}

export const Table = ({
  width = '100%',
  height = 'auto',
  maxHeight,
  columnData,
  rows,
  handleRowClick,
  variant = 'default',
  setSortBy = () => {},
  setSortOrder = () => {},
  isSelectable = false,
  selectedAll = true,
  handleAllClick,
  handleCheckboxClick,
  rowIndexes,
  disabled,
  tableOverflow,
  renderTableRow,
  renderTableCell,
  sortable = false,
}: TableProps) => {
  const {manual_segment_order} = useSelector(selectContractConfiguration)
  const [items, setItems] = useState(manual_segment_order?.length 
    ? [...manual_segment_order.map(id => rows && rows.find && rows.find(row => row && row[0] === id)), ...rows.filter(row => manual_segment_order.indexOf(row[0]) === -1)] 
    : rows);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const dispatch = useDispatch()

  return (
    <TableContainer
      sx={{
        width: width,
        height: height,
        maxHeight: maxHeight,
        overflowX: tableOverflow ? 'visible' : 'auto',
      }}
    >
    {sortable ? <DndContext 
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext 
              items={items}
              strategy={verticalListSortingStrategy}
            >
      <MUITable aria-label='Table'>
        <StyledTableHead>
          <TableRow>
            {isSelectable && (
              <TableCell align='left' width={40}>
                <Checkbox
                  icon={<CheckboxUnchecked />}
                  checkedIcon={<CheckboxChecked />}
                  checked={selectedAll}
                  disabled={disabled}
                  onChange={() => handleAllClick(!selectedAll)}
                />
              </TableCell>
            )}
            {columnData.map((data, idx) => {
              return (
                <TableCell key={idx} align='left' width={data.columnWidth}>
                  {data.sortBy ? (
                    <StyledTableSortHead
                      active={data.columnName == data.sortBy}
                      direction={data.sortOrder}
                      IconComponent={Sort}
                      onClick={() => {
                        setSortOrder(data.sortOrder === 'desc' ? 'asc' : 'desc')
                        setSortBy(data.sortBy)
                      }}
                    >
                      {data.columnName}
                    </StyledTableSortHead>
                  ) : (
                    data.columnName
                  )}
                </TableCell>
              )
            })}
          </TableRow>
        </StyledTableHead>
        <StyledTableBody>
          {items.map((row, idx) => {
              return renderTableRow ? (
                renderTableRow({
                  handleRowClick,
                  handleCheckboxClick,
                  row,
                  idx,
                  disabled,
                  variant,
                  rowIndexes,
                  isSelectable,
                  renderTableCell,
                  columnData,
                })
              ) : (
                <CustomTableRow
                  key={row}
                  handleRowClick={handleRowClick}
                  handleCheckboxClick={handleCheckboxClick}
                  row={row}
                  id={row}
                  idx={idx}
                  disabled={disabled}
                  variant={variant}
                  rowIndexes={rowIndexes}
                  isSelectable={isSelectable}
                  renderTableCell={renderTableCell}
                  columnData={columnData}
                  sortable={sortable}
                  rowsLength={items.length}
                />
              )
            
          })}
        </StyledTableBody>
      </MUITable>
      </SortableContext>
    </DndContext> : <MUITable aria-label='Table'>
        <StyledTableHead>
          <TableRow>
            {isSelectable && (
              <TableCell align='left' width={40}>
                <Checkbox
                  icon={<CheckboxUnchecked />}
                  checkedIcon={<CheckboxChecked />}
                  checked={selectedAll}
                  disabled={disabled}
                  onChange={() => handleAllClick(!selectedAll)}
                />
              </TableCell>
            )}
            {columnData.map((data, idx) => {
              return (
                <TableCell key={idx} align='left' width={data.columnWidth}>
                  {data.sortBy ? (
                    <StyledTableSortHead
                      active={data.columnName == data.sortBy}
                      direction={data.sortOrder}
                      IconComponent={Sort}
                      onClick={() => {
                        setSortOrder(data.sortOrder === 'desc' ? 'asc' : 'desc')
                        setSortBy(data.sortBy)
                      }}
                    >
                      {data.columnName}
                    </StyledTableSortHead>
                  ) : (
                    data.columnName
                  )}
                </TableCell>
              )
            })}
          </TableRow>
        </StyledTableHead>
        <StyledTableBody>
          {rows.map((row, idx) => {
              return renderTableRow ? (
                renderTableRow({
                  handleRowClick,
                  handleCheckboxClick,
                  row,
                  idx,
                  disabled,
                  variant,
                  rowIndexes,
                  isSelectable,
                  renderTableCell,
                  columnData,
                })
              ) : (
                <CustomTableRow
                  key={row}
                  handleRowClick={handleRowClick}
                  handleCheckboxClick={handleCheckboxClick}
                  row={row}
                  id={row}
                  idx={idx}
                  disabled={disabled}
                  variant={variant}
                  rowIndexes={rowIndexes}
                  isSelectable={isSelectable}
                  renderTableCell={renderTableCell}
                  columnData={columnData}
                  sortable={sortable}
                />
              )
            
          })}
        </StyledTableBody>
      </MUITable>}
    </TableContainer>
  )
  function handleDragEnd(event) {
    const {active, over} = event;  
    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.indexOf(active.id);
          const newIndex = items.indexOf(over.id);
          if(items[oldIndex][0] === '99-total' || items[newIndex][0] === '99-total') return items;
          dispatch(setContractConfiguration({manual_segment_order: arrayMove(items, oldIndex, newIndex).map(item => item[0]).filter(idString => idString !== '99-total')}));
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }
}
