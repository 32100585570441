import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { FilterHeader } from 'Components/shared/segmentfilters'
import { Verification } from 'Containers/shared/segmentfilters/ondemand/verification'
import { Demographics } from 'Containers/shared/segmentfilters/ondemand/demographics'
import { ProspectProfile } from 'Containers/shared/segmentfilters/ondemand/prospectprofile'
import { SmartCandidates } from 'Containers/shared/segmentfilters/ondemand/smartcandidates'
import { SingleSelectDropdown } from 'Components/shared/singleselectdropdown'
import { SidebarField } from 'Components/shared/sidebar'
import { buildFilterQuery } from 'Filter/buildFilterQuery'
import { Loading } from 'Utils/loading'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { selectSmartCandidates } from 'Containers/shared/segmentfilters/ondemand/smartcandidates/SmartCandidatesSlice'
import type { RootState } from 'Utils/store'
import {
  AudienceTypeHelper,
  ContractSource,
  ContractType,
  WebSocketMethods,
} from 'Types/index'
import { excludeKeys } from 'Containers/shared/segmentfilters/segmentUtils'
import { useSocket } from 'Hooks/useSocket'
import { useGetAudienceTypesQuery } from 'Services/global/audienceTypes'
import { messageSent } from 'Slices/websocketSlice'
import { Contract } from 'Services/contracts/contractsUtils'
import { useHasPermission } from 'Hooks/useHasPermission'
import { Permissions } from 'Configs/userPermissions'
import { selectGeography } from 'Containers/shared/segmentfilters/ondemand/demographics/GeographySlice'
import { useGetContractConfigQuery } from 'Services/global/contractConfig'
import { SidebarActionButtons } from 'Components/shared/segmentfilters/SidebarActionButtons'
import {selectGroupByFields} from 'Slices/websocketSlice'

interface OnDemandExploreSegmentFilterProps {
  /** Object to derive various properties, like label text for buttons, etc */
  segmentAction?: any
  /** Logic for handling closing the sidebar */
  close?: () => void
}

export const OnDemandExploreSegmentsFilter = ({
  segmentAction,
  close,
}: OnDemandExploreSegmentFilterProps) => {
  const dispatch = useDispatch()
  const { ipedId } = useParams()
  const [resetKey, setResetKey] = useState(Date.now())
  const [socketId, setSocketId] = useState(ipedId + 1)
  const [lookbackPeriod, setLookbackPeriod] = useState<string | undefined>('2 years')
  const { waitingForResponse: isFetchingStudentsAvailable, availableStudents } = useSelector(
    (state: RootState) => state.websocketStatus
  )
  const group_by_fields = useSelector(({websocketStatus}: RootState) => selectGroupByFields(websocketStatus))
  const { sendMessage, messageWrapperForMultiRequests } = useSocket()

  const { data: audienceTypes, isFetching: isFetchingAudienceTypes } =
  useGetAudienceTypesQuery(ContractSource.candidates)
  const geography = useSelector(selectGeography)

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const onDemandExploreSegmentsFilter = useSelector(
    (state: RootState) => state.onDemandFilter
  )

  const { isSmartCandidates, isOnlyEnhanced } = useSelector(selectSmartCandidates)
  const [currentAudienceType, setCurrentAudienceType] = useState(
    AudienceTypeHelper.getNone()
  )
  const { hasPermission: canExploreSegments } = useHasPermission(
    Permissions.ViewEnhancedCandidatesExploreSegments
  )

  const { data: contractConfig } = useGetContractConfigQuery(
    {
      contractSource: ContractSource.candidates,
      contractType: ContractType.on_demand,
      audienceType: currentAudienceType,
    },
    { skip: !currentAudienceType }
  )

  useEffect(() => {
    if(contractConfig) {
    setLookbackPeriod(contractConfig.default_lookback_period)
    }
  }, [contractConfig])

  const resetFilters = () => {
    dispatch({
      type: 'onDemandFilter/reset',
    })
    setResetKey(Date.now())
  }

  const getAdditionalStudents = () => {
    const message = {
      method: WebSocketMethods.students_available_count,
      meta: {
        request_id: `${socketId}-additional`,
      },
      data: {
        is_smart_candidate: true,
        institution_id: ipedId,
        contract_source: ContractSource.candidates,
        audience_type: currentAudienceType,
        filter: buildFilterQuery(
          onDemandExploreSegmentsFilter,
          excludeKeys(ContractSource.candidates, currentAudienceType),
          false,
          ['state', 'zip_code', 'geography']
        ),
        group_by_fields,
        max_look_back_period:
        contractConfig?.available_lookback_periods[lookbackPeriod] || (contractConfig?.available_lookback_periods &&
           contractConfig.available_lookback_periods[contractConfig.default_lookback_period]) ||
          720,
      },
    }
    if (geography.zipFile.id) {
      message.data['zip_id'] = geography.zipFile.id
      message.data['zip_filter_exclusive'] = false
    }
      message.data['override_sources'] = ["smart_candidates"]

    messageWrapperForMultiRequests(JSON.stringify(message), `${socketId}-additional`)
    dispatch(messageSent())
  }

  const getAvailableStudents = () => {
    const message = {
      method: WebSocketMethods.students_available_count,
      meta: {
        request_id: socketId,
      },
      data: {
        is_smart_candidate: isSmartCandidates,
        institution_id: ipedId,
        contract_source: ContractSource.candidates,
        audience_type: currentAudienceType,
        filter: buildFilterQuery(
          onDemandExploreSegmentsFilter,
          excludeKeys(ContractSource.candidates, currentAudienceType),
          false
        ),
        group_by_fields,
        max_look_back_period:
        contractConfig?.available_lookback_periods[lookbackPeriod] || (contractConfig?.available_lookback_periods &&
           contractConfig.available_lookback_periods[contractConfig.default_lookback_period]) ||
          720,
      },
    }
    if (geography.zipFile.id) {
      message.data['zip_id'] = geography.zipFile.id
      message.data['zip_filter_exclusive'] = false
    }
    if(isSmartCandidates) {
      message.data['override_sources'] = isOnlyEnhanced ? ["smart_candidates"] : ["candidates", "smart_candidates"]
    } else {
      message.data['override_sources'] = ["candidates"]
    }

    sendMessage(JSON.stringify(message))
    dispatch(messageSent())
    setSocketId(socketId + 1)
    !geography.zipFile.id && getAdditionalStudents()
  }

  let chosenItem: Partial<Contract> = undefined
  if (!AudienceTypeHelper.isNone(currentAudienceType))
    chosenItem = {
      id: '123-' + currentAudienceType,
      audience_type: currentAudienceType,
    }

  const studentsCount =
    (availableStudents &&
      (Array.isArray(availableStudents)
        ? availableStudents?.reduce((acc, grouping) => grouping.count + acc, 0)
        : availableStudents)) ||
    0

  return (
    <>
      {(isFetchingStudentsAvailable || isFetchingAudienceTypes) && <Loading />}
      <div
        style={{
          width: '396px',
        }}
      >
        <SidebarField id='select-inquiries-type' label='Audience Type'>
        <SingleSelectDropdown
            id='select-audience-type'
            name='inquiriesType'
            value={
              currentAudienceType
                ? AudienceTypeHelper.getReadableString(
                    audienceTypes,
                    currentAudienceType
                  )
                : ''
            }
            style={matches ? { width: '229px' } : {}}
            values={AudienceTypeHelper.getAudienceTypeList(audienceTypes)}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              const chosenItem = AudienceTypeHelper.getAudienceType(
                audienceTypes,
                e.target.value
              )
              setResetKey(Date.now())
              if (chosenItem) {
                setCurrentAudienceType(chosenItem)
              }
            }}
          />
          </SidebarField>
          <SidebarField id='select-lookback-period' label='Lookback Period'>
          <SingleSelectDropdown
            id='select-lookback-period'
            name='candidatesLookbackPeriod'
            value={
              lookbackPeriod + ''}
            style={matches ? { width: '229px' } : {}}
            values={contractConfig?.available_lookback_periods && Object.keys(contractConfig?.available_lookback_periods).sort((a, b) => contractConfig.available_lookback_periods[a] - contractConfig.available_lookback_periods[b]) || ['720']}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setLookbackPeriod(e.target.value)
            }}
          />
        </SidebarField>
        <FilterHeader
          availableStudents={studentsCount}
          isFetchingStudentsAvailable={isFetchingStudentsAvailable}
          resetFilters={resetFilters}
          disableHeader={currentAudienceType ? false : true}
          getAvailableStudents={getAvailableStudents}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            marginBottom: '100px',
          }}
        >
          {canExploreSegments &&
            contractConfig?.sections_available?.includes('SmartCandidates') && (
              <SmartCandidates chosenItem={chosenItem} displayOnlyOption={true}/>
            )}
          <Demographics chosenItem={chosenItem} resetKey={resetKey} />
          <ProspectProfile chosenItem={chosenItem} resetKey={resetKey} />
          <Verification chosenItem={chosenItem} />
        </div>
        {close && (
          <SidebarActionButtons
            handleSegmentAction={getAvailableStudents}
            handleCloseSidebar={close}
            disableFormSubmit={
              isFetchingStudentsAvailable || isFetchingAudienceTypes
            }
            segmentAction={segmentAction}
          />
        )}
      </div>
    </>
  )
}
