import { useState, useRef } from 'react'
import {
  useGetUploadFileDataMutation,
  useUploadFileToS3Mutation,
  useUpdateFileStatusMutation,
} from 'Services/contracts/deduplicates'

import { FileCategory } from 'Types/fileCategory'

/** converts bytes to MB */
const bytesToMB = (bytes) => {
  return bytes / Math.pow(1024, 2)
}

export const useFileUpload = (ipedId: string, fileCategory: FileCategory) => {
  const [isInvalidSize, setIsInvalidSize] = useState(false)
  const [isFileAdded, setIsFileAdded] = useState(false)
  const inputRef = useRef<HTMLInputElement | null>(null)

  const [getUploadData, { isLoading: isUploadingData }] =
    useGetUploadFileDataMutation()
  const [uploadFileToS3, { isLoading: isUploadingFile }] =
    useUploadFileToS3Mutation()
  const [updateFileStatus] = useUpdateFileStatusMutation()

  const handleAddFileClick = () => {
    inputRef.current?.click()
  }

  const handleFileChange = async (event) => {
    const file = event.target.files.item(0)
    const fileName = file.name
    const fileSize = file.size

    if (bytesToMB(fileSize) > 250) {
      setIsInvalidSize(true)
      setIsFileAdded(false)
    } else {
      try {
      const result: any = await getUploadData({
        ipedId,
        name: fileName,
        size_bytes: fileSize,
        is_downloadable: false,
        category: fileCategory,
      })
      if (result?.data?.data?.upload_url) {
        const url = result.data.data.upload_url
        await uploadFileToS3({ url: url, file: file })
        const fileId = result.data.data.file_info.id
        await updateFileStatus({
          ipedId,
          fileId: fileId,
        })
        setIsInvalidSize(false)
        setIsFileAdded(true)
      } else {
        throw new Error('Failed to retrieve upload URL')
      }
    } catch (error) {
      console.error('File updload failed: ', error)
    }
  }
}

  return {
    isInvalidSize,
    isFileAdded,
    isUploadingData,
    isUploadingFile,
    inputRef,
    handleAddFileClick,
    handleFileChange,
    setIsFileAdded,
  }
}

export default useFileUpload
