import React, { memo, useState } from 'react'
import {useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import {
  ContractSection,
  ContractSectionHeader,
} from 'Components/shared/contracts'
import { Table } from 'Components/shared/table'
import { selectContractConfiguration } from 'Slices/contracts/ContractConfigurationSlice'

import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

export function SortableItem({id}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id});
  
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  
  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      test{id}
    </div>
  );
}

const NoSegments = styled('div')(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: theme.typography.fontWeightMedium,
  color: theme.palette.primary.dark,
}))

interface SegmentsListProps {
  segments: any
  flattenSegments: any
  columnData: any
  handleRowClick: (rowIdx: number) => void
}

export const SegmentsListSection = memo(
  ({
    segments,
    flattenSegments,
    columnData,
    handleRowClick,
  }: SegmentsListProps) => {
    const { segment_fill_type } = useSelector(selectContractConfiguration);

    //sortable logic

    return (
      <ContractSection>
        <ContractSectionHeader label={'Segments'} />
        {segments && segments.length > 0 ? (<>
          <Table
            width={'calc(100% - 56px)'}
            height={'248px'}
            columnData={columnData}
            rows={flattenSegments}
            variant='striped'
            handleRowClick={handleRowClick}
            disabled={false}
            sortable={segment_fill_type === 'manual_sort'}
          />
         
        </>) : (
          <NoSegments>No segments have been added to this contract</NoSegments>
        )}
      </ContractSection>
    )

  }
)
