import React, {useState} from 'react'
import Button from '@mui/material/Button'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { alpha } from '@mui/material'
import { InputAdornment, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useForm } from 'react-hook-form'

import { Body, BodyTitle, BodyHeader, BodyContent } from 'Components/shared/body'
import { DialogField, Row } from 'Components/shared/dialog'
import { Input } from 'Components/shared/input'
import { Loading } from 'Utils/loading'
import { useLoginStatusQuery, useChangePasswordMutation } from 'Services/global/authentication'

const SHOW_PASSWORD_NEW_ONE = 2
const SHOW_PASSWORD_NEW_TWO = 4

const Card = styled('div')(({ theme }) => ({
    background: theme.palette.common.white,
    borderRadius: '8px',
    boxShadow: '0px 2px 4px 0px #0000001A',
    marginBottom: '48px',
    padding: '44px 0px',
    paddingLeft: '56px',
    width: '1074px',
    [Row]: {
      marginBottom: '10px',
      marginTop: '24px',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      width: '100%',
    },
  }))

  const AddButton = styled(Button)(({ theme }) => ({
  borderRadius: '4px',
  fontSize: '0.875rem',
  fontWeight: theme.typography.fontWeightMedium,
  height: '40px',
  lineHeight: '18px',
  width: '200px',
  marginLeft: '280px',
  marginTop: '30px',
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  border: 'none',
  boxShadow: `0px 4px 20px ${alpha(theme.palette.common.white, 0.4)}`,
  '&:hover': {
    backgroundColor: `${alpha(theme.palette.primary.main, 0.85)}`,
  },
  '&.Mui-disabled': {
    color: theme.palette.common.white,
    backgroundColor: 'hsla(216, 79%, 72%, 1)',
  }
  }))

  const Requirement = styled('div')(({ theme, error }) => ({
    fontSize: '12px',
    color: error ? 'red' : theme.palette.primary.dark,
    marginLeft: '290px',
    marginBottom: '2px'
  }))

  export const AccountSettingsPage = () => {
    const { data: loginInfo } = useLoginStatusQuery()
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [changePassword, {isLoading}] = useChangePasswordMutation()
    const {
      control,
      formState: { isValid, dirtyFields: {newPassword: isNewPasswordDirty, confirmPassword: isConfirmPasswordDirty} },
    } = useForm({
      mode: 'all',
      resetOptions: { keepValues: false },
    })


    const handleChangeClick = () => {
      changePassword(newPassword)
    }

    const [showPassword, setShowPassword] = useState(0)

  const togglePasswordState = function (toggleKey: number) {
    if (showPassword & toggleKey) setShowPassword(showPassword - toggleKey)
    else setShowPassword(showPassword + toggleKey)
  }

    return <Body>
        <BodyHeader>
            <BodyTitle>Account Settings</BodyTitle>
        </BodyHeader>
        <BodyContent>
            <Card><BodyTitle>Accounts Info</BodyTitle>
            <div>
              <DialogField id='name' label='Name'>
              {loginInfo?.firstName} {loginInfo?.lastName}
                </DialogField>
              <DialogField id='email' label='Email'>
              {loginInfo?.email}
                </DialogField>
              </div></Card>
            <Card><BodyTitle>Password Settings</BodyTitle>
              <DialogField id='new-password' label='Create New password'>
            <Input
              control={control}
              id='create-new-password'
              name='newPassword'
              placeholder='New Password'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewPassword(e.target.value)}
              style={{ 'width': '421px' }}
              rules={{
               validate: {
                  meetsCriteria: (value, {confirmPassword}) => {
                    if(!value.match(/[0-9]+/)) return ''
                    if(!value.match(/[A-Z]+/)) return ''
                    if(!value.match(/[a-z]+/)) return ''
                    if(!value.match(/[*@!#%&$()^~{}]+/)) return ''
                    if(value.length < 8) return ''
                    return value === confirmPassword || ''
                  }
                }
              }}
              type={showPassword & SHOW_PASSWORD_NEW_ONE ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end' sx={{ ml: '-28px' }}>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => togglePasswordState(SHOW_PASSWORD_NEW_ONE)}
                      onMouseDown={(event) => event.preventDefault()}
                      edge='end'
                    >
                      {showPassword & SHOW_PASSWORD_NEW_ONE ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </DialogField>
          <Requirement error={newPassword.length < 8 && isNewPasswordDirty}>at least 8 characters</Requirement>
          <Requirement error={!newPassword.match(/[A-Z]+/) && isNewPasswordDirty}>at least 1 uppercase</Requirement>
          <Requirement error={!newPassword.match(/[a-z]+/) && isNewPasswordDirty}>at least 1 lowercase</Requirement>
          <Requirement error={!newPassword.match(/[0-9]+/) && isNewPasswordDirty}>at least 1 number</Requirement>
          <Requirement error={!newPassword.match(/[*@!#%$&()^~{}]+/) && isNewPasswordDirty}>at least 1 special character</Requirement>
          <Requirement error={newPassword !== confirmPassword && (isConfirmPasswordDirty || isNewPasswordDirty)}>passwords match</Requirement>
          <DialogField id='confirm-new-password' label='Confirm New password'>
            <Input
              control={control}
              id='confirm-new-password'
              name='confirmPassword'
              placeholder='Confirm Password'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>  setConfirmPassword(e.target.value)}
              style={{ width: '421px' }}
              type={showPassword & SHOW_PASSWORD_NEW_TWO ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end' sx={{ ml: '-28px' }}>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => togglePasswordState(SHOW_PASSWORD_NEW_TWO)}
                      onMouseDown={(event) => event.preventDefault()}
                      edge='end'
                    >
                      {showPassword & SHOW_PASSWORD_NEW_TWO ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </DialogField>
          <AddButton onClick={handleChangeClick} disabled={!isValid}>
            Change Password
          </AddButton>
          {isLoading && <Loading/>}
            </Card>
        </BodyContent>
    </Body>
  }