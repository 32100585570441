import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface EditSegment {
  leadTotal: number
  leadAddition: number
}

const initialState: EditSegment = {
  leadTotal: 0,
  leadAddition: null,
}

export const EditSegmentSlice = createSlice({
  name: 'editSegment',
  initialState,
  reducers: {
    setLeadTotal: (state, action: PayloadAction<number>) => {
      state.leadTotal = action.payload
    },
    setLeadAddition: (state, action: PayloadAction<number>) => {
      state.leadAddition = action.payload
    },
    clearEditSegment: (state) => {
      state.leadTotal = 0
      state.leadAddition = null
    },
  },
})

export const { setLeadTotal, clearEditSegment, setLeadAddition } = EditSegmentSlice.actions

export default EditSegmentSlice.reducer
