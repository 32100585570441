import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'Utils/store'
import { multiSelectSlice } from 'Slices/multiSelectSlice'
import LastDegreeCompletedConfig from 'Configs/fields/last_degree_completed.json'
import { ContractSource } from 'Types/contractTypes'

const contractSource = ContractSource.candidates

const transformValues = function (selectedValues) {
  return selectedValues.map((value) => (value === 'None Selected' ? '' : value))

}

export const LastDegreeCompletedSlice = multiSelectSlice(
  'recent_degree',
  'setLastDegreeCompleted',
  LastDegreeCompletedConfig.values,
  LastDegreeCompletedConfig.meta,
  transformValues
)

export const selectLastDegreeCompleted = createSelector(
  ({ onDemandFilter: { prospectProfile } }: RootState) =>
    prospectProfile.lastDegreeCompleted.selectedValues,
  (
    selectedValues: string[]
  ): {
    selectedValues: string[]
    values: string[]
    audienceTypes: string[]
    label
  } => {
    return {
      selectedValues,
      values: LastDegreeCompletedConfig.values,
      audienceTypes: LastDegreeCompletedConfig.source[contractSource].audience_types,
      label: LastDegreeCompletedConfig.meta.label,
    }
  }
)

export const { setLastDegreeCompleted } = LastDegreeCompletedSlice.actions

export default LastDegreeCompletedSlice.reducer
