import { createApi } from '@reduxjs/toolkit/query/react'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { baseQuery } from '../getBaseQuery'

interface GetActiveSegmentsResponse {
  data: {
    records: any[]
    total_pages: number
    total_records: number
    current_page: number
    current_size: number
  }
}
interface GetActiveSegmentsTransformedResponse {
  records: any[]
  total_pages: number
  total_records: number
}

const HIP_LEADS = 'HIP_LEADS'

export const HipLeadsApi = createApi({
  reducerPath: 'addSegment',
  baseQuery,
  tagTypes: [HIP_LEADS],
  endpoints: (build) => ({
    /** Get all active segments  - HIP Leads */
    getActiveSegments: build.query<
      GetActiveSegmentsTransformedResponse,
      { page: number; pageSize: number, sortBy: string; order: string; filter?: {query: {filterQuery}}, searchString?: string }
    >({
      query: ({ page, pageSize, sortBy, order, filter, searchString }) => {
        const params = {}
        params['filter'] = JSON.stringify(searchString ? {
          operation: 'AND',
          properties: [
            {
              operation: 'OR',
              properties: [
                {
                  column: 'institution_name',
                  op: 'like',
                  value: `%${searchString}%`,
                },
                {
                  column: 'institution_id',
                  op: 'like',
                  value: `%${searchString}%`,
                },
              ],
            },
            {...filter?.query?.filterQuery}
          ]
        } : filter?.query?.filterQuery)
        return {url: `segments/details?page=${page}&size=${pageSize}&sort_by=${sortBy}::${order}`, params}
      },
      providesTags: [HIP_LEADS],
      transformResponse: (response: GetActiveSegmentsResponse) => {
        const segments: GetActiveSegmentsTransformedResponse = {
          total_pages: response.data.total_pages,
          total_records: response.data.total_records,
          records: response.data.records,
        }
        return segments
      },
    }),

    /** get all contracts for an institution */
    getAllActiveSegments: build.query<
      { data: any[] },
      {
        filterQuery: string | object
      }
    >({
      queryFn: async (
        { filterQuery },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) => {
        const firstPageSegments = await fetchWithBQ({
          url: `segments/details?page=0&size=10&sort_by=updated_at::desc&filter=${filterQuery}`,
        })
        if (firstPageSegments?.error)
          return { error: firstPageSegments?.error as FetchBaseQueryError }

        let allSegments = firstPageSegments?.data?.['data']?.['records'] ?? []

        const totalPages =
          firstPageSegments?.data?.['data']?.['total_pages'] ?? 0

        for (let page = 1; page < totalPages; page++) {
          const segments = await fetchWithBQ({
            url: `segments/details?page=${page}&size=10&sort_by=updated_at::desc&filter=${filterQuery}`,
          })
          allSegments = [
            ...allSegments,
            ...segments?.data?.['data']?.['records'],
          ]
        }

        return { data: allSegments }
      },
      providesTags: [HIP_LEADS],
    }),

    /** Add a segment supplement - this updates the existing inquiry segment */
    addSegment: build.mutation<void, any>({
      query: ({ ipedId, segmentId, ...args }) => ({
        url: `institutions/${ipedId}/segments/${segmentId}/supplements`,
        body: args,
        method: 'POST',
      }),
      invalidatesTags: [HIP_LEADS],
    }),

    /** Update a segment supplement */
    updateSegment: build.mutation<void, any>({
      query: ({ ipedId, segmentId, ...args }) => ({
        url: `institutions/${ipedId}/segments/${segmentId}/supplements`,
        body: args,
        method: 'PATCH',
      }),
      invalidatesTags: [HIP_LEADS],
    }),

    /** Activate or Deactivate a segment */
    setActiveForSegment: build.mutation<
      void,
      { ipedId: string; segmentId: string; status: string }
    >({
      query: ({ ipedId, segmentId, ...args }) => ({
        url: `institutions/${ipedId}/segments/${segmentId}/supplements`,
        body: args,
        method: 'PATCH',
      }),
      invalidatesTags: [HIP_LEADS],
    }),
  }),
})

export const {
  useGetActiveSegmentsQuery,
  useGetAllActiveSegmentsQuery,
  useAddSegmentMutation,
  useUpdateSegmentMutation,
  useSetActiveForSegmentMutation,
} = HipLeadsApi
