import React, { memo } from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {
  Dialog,
  DialogActionButtons,
  DialogField,
  DialogFieldContainer,
} from 'Components/shared/dialog'

import { styled, useTheme } from '@mui/material/styles'
import { alpha } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import Button from '@mui/material/Button'
import { Input } from 'Components/shared/input'
import TextField from '@mui/material/TextField'
import { SingleSelectDropdown } from 'Components/shared/singleselectdropdown'
import { VirtualizedSingleSelect } from 'Components/shared/singleselectdropdown'
import { InstitutionResponse } from 'Services/global/institution'
import { Contract } from 'Services/contracts/contractsUtils'
import { Segment } from 'Services/segments/segmentUtils'
import { current } from '@reduxjs/toolkit'
import { setLeadAddition } from 'Containers/admin/hipLeads/EditSegmentSlice'
import type { RootState } from 'Utils/store'



const StyledButton = styled(Button)(({ theme }) => ({
  border: `1.8px solid ${theme.palette.primary.main}`,
  borderRadius: '4px',
  fontSize: '0.875rem',
  fontWeight: theme.typography.fontWeightMedium,
  height: '40px',
  lineHeight: '18px',
  minWidth: 'calc(100% - 40px)',
  [theme.breakpoints.up('md')]: {
    minWidth: 'auto',
  },
}))

const CancelButton = styled(StyledButton)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.primary.main,
  lineHeight: '20px',
  '&:hover': {
    backgroundColor: `${alpha(theme.palette.primary.light, 0.4)}`,
  },
  [theme.breakpoints.up('md')]: {
    width: '110px',
  },
}))

const UpdateButton = styled(StyledButton)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  border: 'none',
  boxShadow: `0px 4px 20px ${alpha(theme.palette.common.white, 0.4)}`,
  '&:hover': {
    backgroundColor: `${alpha(theme.palette.primary.main, 0.85)}`,
  },
  '&.Mui-disabled': {
    color: theme.palette.common.white,
    backgroundColor: 'hsla(216, 79%, 72%, 1)',
  },
  [theme.breakpoints.up('md')]: {
    width: '155px',
  },
}))

interface EditSegmentProps {
  /** To show the edit segment dialog or not */
  editSegmentDialog: boolean
  /** this is the current segment */
  currentSegment: any
  /** Segment Lead Total */
  leadTotal: number
  /** To set the Segment Lead Total */
  setSegmentLeadTotal: (value: number) => void
  /** To submit the edit segment values */
  editSegmentValues: () => void
  /** Clean up actions when closing the dialog */
  closeDialog: () => void
  /** Boolean that determines whether to disable submit  */
  disableSubmit: boolean
  /** Object that registers component into React Hook Form for validation*/
  control?: any
}

export const EditSegment = memo(
  ({
    editSegmentDialog,
    currentSegment,
    editSegmentValues,
    leadTotal,
    setSegmentLeadTotal,
    closeDialog,
    disableSubmit,
    control,
  }: EditSegmentProps) => {
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.up('md'))
    const dispatch = useDispatch()
    const { leadAddition } = useSelector((state: RootState) => state.editLeadSegment)
    return (
      <Dialog
        onClose={closeDialog}
        open={editSegmentDialog}
        title='Update Segment'
      >
        <DialogFieldContainer>
          <DialogField id='partner' label='Partner'>
            {currentSegment?.institution_name}
          </DialogField>

          <DialogField id='contract' label='Contract Related'>
            {currentSegment?.contract_label}
          </DialogField>

          <DialogField id='segment-name' label='Segment Name'>
            {currentSegment?.name}
          </DialogField>
          <DialogField id='edit-leads-total' label='# of Segment Leads Request'>
            <Input
              id='edit-leads-total'
              name='leadsTotal'
              value={leadAddition}
              control={control}
              placeholder='0'
              style={matches ? { width: '160px' } : {}}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                dispatch(setLeadAddition(Number(e.target.value)))
              }
              rules={{
                required: true,
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Must contain only numbers',
                },
              }}
            />
          </DialogField>
        </DialogFieldContainer>

        <DialogActionButtons>
          <CancelButton onClick={closeDialog}>Cancel</CancelButton>
          <UpdateButton disabled={disableSubmit} onClick={editSegmentValues}>
            Update Segment
          </UpdateButton>
        </DialogActionButtons>
      </Dialog>
    )
  }
)
