import React, { useCallback, useEffect, memo } from 'react'
import { useToggle } from 'Hooks/useToggle'
import { Accordion } from 'Components/shared/accordion'
import { SidebarFieldWrapper } from 'Components/shared/sidebar'
import { GpaTestScores } from './GpaTestScores'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectLastDegreeCompleted,
  setLastDegreeCompleted,
} from './LastDegreeCompletedSlice'
import {
  selectLevelOfDegreeSeeking,
  setLevelOfDegreeSeeking,
} from './LevelOfDegreeSeekingSlice'
import {
  selectCurrentlyEnrolled,
  setCurrentlyEnrolled,
} from './CurrentlyEnrolledSlice'
import {
  selectIntendedMajor,
  setTimeBasedIntendedMajors,
} from './IntendedMajorSlice'
import {
  selectIntendedAreaofStudy,
  setTimeBasedIntendedAreaofStudy,
} from './IntendedAreaofStudySlice'
import {
  selectGraduationYear,
  setTimeBasedGraduationYearRange,
} from './GraduationYearSlice'

import {
  selectStudyAbroad,
  setStudyAbroad,
} from 'Slices/segments/StudyAbroad'

import {
  selectReligiousInstitution,
  setReligiousInstitution,
} from 'Slices/segments/ReligiousInstitution'

import { Slider } from 'Components/shared/slider'
import { SidebarField } from 'Components/shared/sidebar'
import { Gpa as GpaComponent } from 'Components/shared/segmentfilters/prospectprofile/Gpa'

import { FilterSingleSelectDropdown } from 'Components/shared/singleselectdropdown'
import { VirtualizedMultiSelect } from 'Components/shared/multiselectdropdown'
import { selectGpa, setTimeBasedAnyGpa, setTimeBasedGpaRange } from './GpaSlice'
import { selectGpaTestScores } from './GpaTestScoresSlice'
import { Contract } from 'Services/contracts/contractsUtils'
import { setLevelOfDegreeSeekingDefaultValue } from './LevelOfDegreeSeekingSlice'
import { setGraduationYearDefaultValue } from './GraduationYearSlice'

interface ProspectProfileProps {
  chosenItem: Partial<Contract>
  filterData?: any
  segmentId?: string
  resetKey: number
}

export const ProspectProfile = memo(
  ({ chosenItem, segmentId, filterData, resetKey }: ProspectProfileProps) => {
    const dispatch = useDispatch()
    const [expand, setExpand] = useToggle(false)

    const gpa = useSelector(selectGpa)
    const gpaTestScores = useSelector(selectGpaTestScores)
    const levelOfDegreeSeeking = useSelector(selectLevelOfDegreeSeeking)
    const lastDegreeCompleted = useSelector(selectLastDegreeCompleted)
    const currentlyEnrolled = useSelector(selectCurrentlyEnrolled)
    const intendedMajor = useSelector(selectIntendedMajor)
    const intendedAreaofStudy = useSelector(selectIntendedAreaofStudy)
    const graduationYear = useSelector(selectGraduationYear)
    const studyAbroad = useSelector(selectStudyAbroad)
    const religiousInstitution = useSelector(selectReligiousInstitution)


    /** Set Level of Degree Seeking based on the audience type */
    useEffect(() => {
      const audienceType = chosenItem?.audience_type
      setLevelOfDegreeSeekingDefaultValue(dispatch, audienceType)
      setGraduationYearDefaultValue(dispatch, audienceType)
      dispatch(setTimeBasedAnyGpa({ audienceType: chosenItem?.audience_type, selections: ['any'] }))
    }, [chosenItem?.id, resetKey])

    /** This useEffect is for setting the filters in edit segment */
    useEffect(() => {
      if (segmentId && filterData) {
        if (filterData?.[levelOfDegreeSeeking.label]) {
          dispatch(
            setLevelOfDegreeSeeking(filterData[levelOfDegreeSeeking.label])
          )
        }

        if (filterData?.[intendedAreaofStudy.label])
          dispatch(
            setTimeBasedIntendedAreaofStudy(
              filterData[intendedAreaofStudy.label]
            )
          )

        if (filterData?.[lastDegreeCompleted.label]) {
          dispatch(
            setLastDegreeCompleted(filterData?.[lastDegreeCompleted.label])
          )
        }
        if (filterData?.[currentlyEnrolled.label]) {
          dispatch(setCurrentlyEnrolled(filterData?.[currentlyEnrolled.label]))
        }

        if (filterData?.[graduationYear.label]) {
          const range = filterData?.[graduationYear.label]
          dispatch(
            setTimeBasedGraduationYearRange({
              min: range[0],
              max: range[1],
            })
          )
        }

        if (filterData?.[gpa.label]) {
          const value = filterData?.[gpa.label]
            dispatch(
              setTimeBasedAnyGpa({
                audienceType: chosenItem?.audience_type, selections: value
              })
            )
        }

        if(filterData?.[studyAbroad.label]) {
          dispatch(setStudyAbroad(filterData[studyAbroad.label]))
        }

        if(filterData?.[religiousInstitution.label]) {
          dispatch(setReligiousInstitution(filterData[religiousInstitution.label]))
        }
      }
    }, [filterData])

    const dispatchLevelOfDegreeSeeking = useCallback((values: string[]) => {
      dispatch(setLevelOfDegreeSeeking(values))
    }, [])

    const dispatchLastDegreeCompleted = useCallback((values: string[]) => {
      dispatch(setLastDegreeCompleted(values))
    }, [])

    const dispatchCurrentlyEnrolled = useCallback((values: string[]) => {
      dispatch(setCurrentlyEnrolled(values))
    }, [])

    const dispatchIntendedMajor = useCallback((values: string[]) => {
      dispatch(setTimeBasedIntendedMajors(values))
    }, [])

    const dispatchIntendedAreaofStudy = useCallback((values: string[]) => {
      dispatch(setTimeBasedIntendedAreaofStudy(values))
    }, [])

    const dispatchGraduationYearRange = useCallback(({ min, max }) => {
      dispatch(setTimeBasedGraduationYearRange({ min, max }))
    }, [])

    const dispatchAnyGpa = useCallback((selections) => {
      dispatch(setTimeBasedAnyGpa({ audienceType: chosenItem?.audience_type, selections }))
    }, [chosenItem?.id])

    const dispatchGpaRange = useCallback(
      ({ min, max }) => {
        dispatch(
          setTimeBasedGpaRange({
            min,
            max,
            audienceType: chosenItem?.audience_type,
          })
        )
      },
      [chosenItem]
    )

    return (
      <Accordion
        setExpand={setExpand}
        expand={expand}
        title='PROSPECT PROFILE'
        disabled={chosenItem?.id ? false : true}
      >
        <SidebarFieldWrapper>
          {graduationYear.audienceTypes.includes(chosenItem?.audience_type) && (
            <SidebarField id='graduation year' label='Graduation Year'>
              <Slider
                ariaLabel={graduationYear.ariaLabel}
                min={graduationYear.minYear}
                max={graduationYear.maxYear}
                selectedMin={graduationYear.selectedMinGradYear}
                selectedMax={graduationYear.selectedMaxGradYear}
                step={graduationYear.step}
                handleChange={(range: number[]) =>
                  dispatchGraduationYearRange({ min: range[0], max: range[1] })
                }
              />
            </SidebarField>
          )}
          {intendedMajor.audienceTypes.includes(chosenItem?.audience_type) && (
            <VirtualizedMultiSelect
              label={intendedMajor.label}
              counterLabel={'Major'}
              initialSelectedValues={intendedMajor.selectedValues}
              selectedValues={intendedMajor.selectedValues}
              values={intendedMajor.values}
              setSelectedValues={dispatchIntendedMajor}
            />
          )}

          {intendedAreaofStudy.audienceTypes.includes(
            chosenItem?.audience_type
          ) && (
            <VirtualizedMultiSelect
              label={intendedAreaofStudy.label}
              counterLabel={'Area Of Study'}
              values={intendedAreaofStudy.values}
              initialSelectedValues={intendedAreaofStudy.selectedValues}
              selectedValues={intendedAreaofStudy.selectedValues}
              setSelectedValues={dispatchIntendedAreaofStudy}
            />
          )}
          {levelOfDegreeSeeking.audienceTypes.includes(
            chosenItem?.audience_type
          ) && (
            <VirtualizedMultiSelect
              label={levelOfDegreeSeeking.label}
              counterLabel={'Degree'}
              values={levelOfDegreeSeeking.values}
              initialSelectedValues={levelOfDegreeSeeking.selectedValues}
              selectedValues={levelOfDegreeSeeking.selectedValues}
              setSelectedValues={dispatchLevelOfDegreeSeeking}
            />
          )}

          {gpaTestScores.audienceTypes.includes(chosenItem?.audience_type) && (
            <GpaTestScores audienceType={chosenItem?.audience_type} />
          )}

          {gpa.audienceTypes.includes(chosenItem?.audience_type) && (
            <GpaComponent
              dispatchAnyGpa={dispatchAnyGpa}
              dispatchGpaRange={dispatchGpaRange}
              gpaState={gpa}
            />
          )}

          {lastDegreeCompleted.audienceTypes.includes(
            chosenItem?.audience_type
          ) && (
            <VirtualizedMultiSelect
              label='Most Recent Degree'
              counterLabel={'Degree'}
              values={lastDegreeCompleted.values}
              initialSelectedValues={lastDegreeCompleted.selectedValues}
              selectedValues={lastDegreeCompleted.selectedValues}
              setSelectedValues={dispatchLastDegreeCompleted}
            />
          )}

          {currentlyEnrolled.audienceTypes.includes(
            chosenItem?.audience_type
          ) && (
            <VirtualizedMultiSelect
              label={currentlyEnrolled.label}
              counterLabel={'Enrolled'}
              values={currentlyEnrolled.values}
              selectedValues={currentlyEnrolled.selectedValues}
              initialSelectedValues={currentlyEnrolled.selectedValues}
              setSelectedValues={dispatchCurrentlyEnrolled}
              />
          )}
          {studyAbroad.audienceTypes.includes(chosenItem?.audience_type) && (
            <VirtualizedMultiSelect
              label={studyAbroad.label}
              counterLabel={'Study Abroad'}
              values={studyAbroad.values}
              initialSelectedValues={studyAbroad.selectedValues}
              selectedValues={studyAbroad.selectedValues}
              setSelectedValues={(values) => dispatch(setStudyAbroad(values))}
              />)
          }
          {religiousInstitution.audienceTypes.includes(chosenItem?.audience_type) && (
            <VirtualizedMultiSelect
              label={religiousInstitution.label}
              counterLabel={'Religious Institution'}
              values={religiousInstitution.values}
              initialSelectedValues={religiousInstitution.selectedValues}
              selectedValues={religiousInstitution.selectedValues}
              setSelectedValues={(values) => dispatch(setReligiousInstitution(values))}
              />)
          }
        </SidebarFieldWrapper>
      </Accordion>
    )
  }
)
