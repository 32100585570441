import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'Utils/store'
import { multiSelectSlice } from 'Slices/multiSelectSlice'
import SliceConfig from 'Configs/fields/utm_campaign.json'
import { ContractSource } from 'Types/contractTypes'
import { CandidateContractsApi } from 'Services/contracts/candidatesContracts'

const contractSource = ContractSource.candidates

const transformValues = function (selectedValues) {
  return selectedValues.map((value) => (value === 'None Selected' ? '' : value))
}

const extraReducer = (builder) => {
  builder.addMatcher(
    CandidateContractsApi.endpoints.getUTMCampaign.matchFulfilled,
    (state, action) => {
      state.values = action.payload.data.map((item) => item?.utm_campaign).filter(val => !!val)
    }
  )
}

export const UTMCampaignSlice = multiSelectSlice(
  'utm_campaign',
  'setUTMCampaign',
  SliceConfig.values,
  SliceConfig.meta,
  transformValues,
  null,
  extraReducer
)

export const selectUTMCampaign = createSelector(
  ({ onDemandFilter: { prospectProfile } }: RootState) => prospectProfile.UTMCampaign.selectedValues,
  ({ onDemandFilter: { prospectProfile } }: RootState) => prospectProfile.UTMCampaign.values,
  (
    selectedValues: string[],
    values: string[]
  ): {
    selectedValues: string[]
    values: string[]
    audienceTypes: string[]
    label
  } => {
    return {
      selectedValues,
      values,
      audienceTypes: SliceConfig.source[contractSource].audience_types,
      label: SliceConfig.meta.label,
    }
  }
)

export const { setUTMCampaign } = UTMCampaignSlice.actions

export default UTMCampaignSlice.reducer