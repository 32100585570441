import moment from 'moment'

export const formatDate = (date: string) => {
  return date ? moment.utc(date).format('MM/DD/yyyy') : null
}

export const formatFileDate = (date: string) => {
  return date ? moment.utc(date).format('MMM D, YYYY') : null
}

export const formatContractDate = (date: string) => {
  return date ? moment.utc(date).format() : null
}

export const stringifyContractDate = (date: string) => {
  return date ? moment.utc(date).format('MM/DD/yyyy') : null
}

export function yearRangeToDateRange(minYear: number, maxYear: number) {
  const minDate = moment().year(minYear).month(0).date(1).format('yyyy-MM-DD')
  const maxDate = moment().year(maxYear).month(11).date(31).format('yyyy-MM-DD')
  return [minDate, maxDate]
}

export function dateRangeToYearRange(minDateString, maxDateString) {
  const minYear = moment(minDateString, 'yyyy-MM-DD').year()
  const maxYear = moment(maxDateString, 'yyyy-MM-DD').year()

  return [minYear, maxYear]
}

export const findDateFromDays = (range: string) => {
  // the values should be offset by 1, to handle "today"
  const recencyRange = {
    'Within Last 3 Days': -4,
    'Within Last 7 Days': -8,
    'Within Last 30 Days': -31,
    'Within Last 3 Months': -91,
    'Within Last 12 Months': -366,
  }

  const days = recencyRange[range]
  const date = new Date()
  date.setDate(date.getDate() + (1 + days))

  let month = `${date.getMonth() + 1}`
  let day = `${date.getDate()}`
  const year = date.getFullYear()

  if (month.length < 2) {
    month = '0' + month
  }
  if (day.length < 2) {
    day = '0' + day
  }

  return [year, month, day].join('-')
}
