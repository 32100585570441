import React from 'react'
import { styled } from '@mui/material/styles'
import { Input, ErrorMessage } from 'Components/shared/input'
import { useState, useCallback } from 'react'
import { isValidEmail } from 'Utils/validation'
import {
    useGeneratePasscodeMutation,
    usePasscodeLogInMutation
  } from 'Services/global/authentication'
import { Loading } from 'Utils/loading' 
import { LoginSubHeading, LoginBtnText, InputFlexContainer, ForgotPassword, ForgotPasswordText, LoginButton, CustomLink, LoginHeading } from 'Components/connectedComponents/login'


interface LoginProps {
  exitPasscodeLogin: () => void
}

const FirstTimeMessage = styled('div')(() => ({
  fontSize: '14px',
  marginBottom: '20px',
  backgroundColor: 'rgb(237, 241, 250)',
  position: 'absolute',
  width: '78%',
  borderRadius: '22px',
  top: '-200px',
  padding: '20px 25px 40px 35px',
  [LoginHeading]: {
    marginBottom: '12px',
    fontWeight: 'semi-bold'
  }
}))

export const OneTimePasswordLogin = ({ exitPasscodeLogin }: LoginProps) => {
  const [password, setPassword] = useState('');
  const [validationError, setValidationError] = useState(undefined);
  const searchParams = new URLSearchParams(window?.location?.search)
  const firstTimeLogin = searchParams.get('first_time_login')
  const searchParamEmail = searchParams.get('email') || ''
  const firstName = searchParams.get('first_name')
  const [email, setEmail] = useState(searchParamEmail);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };
  const [generatePasscode, {data, isError, isLoading, isSuccess, reset}] = useGeneratePasscodeMutation()
  const [loginWithPasscode, {isError: isPasscodeError,  isLoading:isPasscodeLoading, isSuccess: isLoginSuccess, reset: resetPassCode}] = usePasscodeLogInMutation()
  const handleLoginClick = useCallback(() => {
    if (isSuccess) {
        if(!password) {
            setValidationError('Missing required fields') 
        } else {
            setValidationError(undefined) 
            loginWithPasscode({token: password, token_id: data?.data})
        }
    } else if (searchParamEmail) {
      setValidationError(undefined)
      generatePasscode(searchParamEmail)
    } else if (!email) {
      setValidationError('Missing required fields')
    } else if(!isValidEmail(email)) {
      setValidationError('Invalid email')
    }else {
      setValidationError(undefined)
      generatePasscode(email)
    }
  }, [email, password]);
  const border = (validationError || isError || isPasscodeError) ? '1px solid #e34c4c' : undefined
  const subheader = isSuccess ? `Please Enter the six digit passcode sent to ${email}` : isLoading ? "Sending Passcode..." : isLoginSuccess ? "Logging In..." : "Enter your email to recieve a one time password"
  const loginText = isLoginSuccess ? <Loading/> : isSuccess ? 'Login' : 'Send Passcode'

  return (
    <>
    {firstTimeLogin && <FirstTimeMessage><LoginHeading>Welcome, {firstName}!</LoginHeading>
      <div>To get started, simply use the button below, and we'll send you a <b>6-digit code</b> to securely access your account. 
        No Need to remember any passwords—just enter the code below, and you'll be logged in instantly. It's <b>quick, easy,</b> and <b>safe!</b></div>
    </FirstTimeMessage>}
      <LoginSubHeading>{subheader}</LoginSubHeading>
        {!isSuccess && !isLoading && <>
            <InputFlexContainer>
                <Input
                    placeholder='Email'
                    style={{ width: '100%' }}
                    border={border}
                    value={email}
                    onChange={handleEmailChange}
                />
                {isError && <ErrorMessage style={{ margin: 0 }}>Email Not Recognized, try again</ErrorMessage>}
                {validationError && <ErrorMessage style={{ margin: 0 }}>{validationError}</ErrorMessage>}
            </InputFlexContainer>
            <ForgotPassword>
                Or <CustomLink onClick={exitPasscodeLogin}><ForgotPasswordText>sign in with a password</ForgotPasswordText></CustomLink>
            </ForgotPassword>
        </>}
        {isLoading && <InputFlexContainer><Loading/></InputFlexContainer>}
        {isSuccess && <><InputFlexContainer>
        {isPasscodeLoading && <Loading/>}
            <Input
              placeholder='Passcode'
              style={{ width: '100%' }}
              border={border}
              value={password}
              onChange={handlePasswordChange}
            />
            {isPasscodeError && <ErrorMessage style={{ margin: 0 }}>Invalid Passcode</ErrorMessage>}
          </InputFlexContainer> 
          <ForgotPassword>
            Or <CustomLink onClick={() => {reset(); resetPassCode(); setPassword('')}}><ForgotPasswordText>re-request passcode</ForgotPasswordText></CustomLink>
          </ForgotPassword>
        </>}
        <LoginButton disabled={(!email && !firstTimeLogin) || (isSuccess && !password) || (isLoginSuccess)} onClick={handleLoginClick}>
            <LoginBtnText >{loginText}</LoginBtnText>
        </LoginButton>
    </>
  )
}