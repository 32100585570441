import React, { useMemo, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {
  Body,
  BodyTitle,
  BodyActions,
  BodyHeader,
  BodyContent,
} from 'Components/shared/body'
import { TablePagination } from 'Containers/shared/tablePagination'
import { TableFooter } from 'Components/shared/table'
import { AddFileButton } from 'Components/shared/buttons/AddfileButton'
import { useGetZipCodesQuery } from 'Services/configuration/zipCodes'
import { usePagination } from 'Hooks/usePagination'
import { Loading } from 'Utils/loading'
import { Table } from 'Components/shared/table'
import { buildFilterQuery } from 'Filter/buildFilterQuery'
import useFileUpload from 'Hooks/useFileUpload'
import { DeleteZipCode } from './deletezipcode'
import { Permissions } from 'Configs/userPermissions'
import { useHasPermission } from 'Hooks/useHasPermission'
import { FileCategory } from 'Types/fileCategory'

const columnData = [
  { columnName: 'File Name', columnWidth: 550, sortOrder: 'ASC' },
  { columnName: 'Size In Bytes', columnWidth: 150, sortOrder: 'ASC' },
  { columnName: '', columnWidth: 150, sortOrder: 'ASC' },
]

export const getFilesfilterQuery = {
  query: {
    filterQuery: {
      properties: [
        {
          column: 'category',
          op: 'eq',
          value: FileCategory.ZipSegmentFilter,
        },
      ],
      operation: 'AND',
    },
  },
}

export const ZipCodes = () => {
  const { currentPage, handlePageChange } = usePagination()
  const { ipedId } = useParams()
  const {
    isFileAdded,
    isUploadingData,
    isUploadingFile,
    inputRef,
    handleAddFileClick,
    handleFileChange,
    setIsFileAdded,
  } = useFileUpload(ipedId, FileCategory.ZipSegmentFilter)
  const { hasPermission: canUploadFile } = useHasPermission(
    Permissions.SwitchInstitution
  )
  const { hasPermission: canDeleteFile } = useHasPermission(
    Permissions.AddContractDedup
  )

  const { data: zipCodesData, isLoading, refetch } = useGetZipCodesQuery({
    ipedId,
    page: currentPage,
    sortBy: 'updated_at',
    order: 'desc',
    filter: buildFilterQuery(getFilesfilterQuery),
  })

  useEffect(() => {
    if(isFileAdded) {
      refetch()
      setIsFileAdded(false)
    }
  }, [isFileAdded])

  const flattenZipCodes = useMemo(() => {
    let rows = []
    if (zipCodesData) {
      zipCodesData.records?.forEach((zipCode: any) => {
        rows.push([
          zipCode.id,
          zipCode.name,
          zipCode.size,
          canDeleteFile ? (
            <DeleteZipCode fileId={zipCode.id} fileName={zipCode.name} />
          ) : (
            ''
          ),
        ])
      })
    }
    return { rows }
  }, [zipCodesData])

  if (isLoading || isUploadingData || isUploadingFile) return <Loading />

  return (
    <Body>
      <BodyHeader>
        <BodyTitle>Zipcodes</BodyTitle>
        <BodyActions>
          {canUploadFile && (
            <AddFileButton onClick={handleAddFileClick}>
              <input
                ref={inputRef}
                type='file'
                onChange={handleFileChange}
                style={{ display: 'none' }}
                accept='.csv, .txt'
              />
            </AddFileButton>
          )}
        </BodyActions>
      </BodyHeader>
      <BodyContent>
        {zipCodesData?.records?.length > 0 && (
          <>
            <Table columnData={columnData} rows={flattenZipCodes.rows} />
            <TableFooter>
              {zipCodesData?.total_pages > 1 && (
                <TablePagination
                  rowsPerPage={10}
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                  totalRecords={zipCodesData?.total_records}
                  totalPages={zipCodesData?.total_pages}
                />
              )}
            </TableFooter>
          </>
        )}
      </BodyContent>
    </Body>
  )
}
