import React from 'react'
import {useSelector} from 'react-redux'
import { PieChart } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';
import { selectVisualizationDataByChartId } from 'Slices/websocketSlice'
import { TableVisualization } from 'Containers/shared/segmentdisplay';

const chartColors = ['rgb(9, 94, 229)', 'rgb(67, 138, 246)', 'rgb(146, 185, 245)', 'rgb(200, 222, 255)']
const altColors = ['rgb(3, 177, 176)', 'rgb(127, 207, 207)', 'rgb(161, 220, 220)', 'rgb(224, 240, 240)']

export const StudentVisualization = ({chartId, dataSet, secondary = false}) => {
    const {
        studentsVisualizedForChart, 
        metricLabel, 
        chartLabel, 
        chartType, 
        secondMetricSet, 
        secondMetric
    } = useSelector(({websocketStatus: {charts}}) => selectVisualizationDataByChartId({availableStudents: dataSet, charts}, chartId))
    
    if (studentsVisualizedForChart?.length === 0) return <div>No data available</div>
    
    if(chartType === 'pie') {
        return (
            <PieChart
            series={[{data: studentsVisualizedForChart, 
                outerRadius: 100,
                paddingAngle: 0,
            }]}
            height={300}
            width={400}
            colors={secondary ? altColors : chartColors}
            slotProps={{legend: 
                {position: {vertical: 'bottom', horizontal: 'middle'}, 
                direction: 'row',
                padding: {top: 260, bottom: 0, left: 0, right: 0},
                labelStyle: {fontSize: 13, fontWeight: 'medium'},
                itemMarkWidth: 7,
                itemMarkHeight: 7,
            }}}
            sx={{
                [`.MuiPieArc-root`]: {strokeWidth: '3px'},
            }}
            
          />
        )
    }
    if(chartType === 'bar') {
        return (
            <BarChart
            colors={secondary ? altColors : chartColors}
            dataset={studentsVisualizedForChart}
            yAxis={[{ scaleType: 'band', dataKey: 'label', label: `Student ${metricLabel}` }]}
            xAxis={[{ label: 'Students', tickMinStep: 50 }]}
            series={[{ dataKey: 'value', label: chartLabel}]}
            layout="horizontal"
            height={420}
            width={400}
            grid={{vertical: true}}
          />
        )
    }
    if(chartType === 'table') {
        return (
            <TableVisualization rows={studentsVisualizedForChart} columns={Array.from(secondMetricSet)} title={secondMetric ? "High School Graduation Class" : ""} label={metricLabel} />
        )
    }
    return <div>No Visualization available</div>
}