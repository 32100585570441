import React, { useMemo, useEffect, useState } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  CandidatesFilesFilter,
  setCandidatesContract,
} from 'Containers/candidates/files/filesfilter'
import { FilesFilterHeader } from 'Components/shared/sidebar'
import { Permissions } from 'Configs/userPermissions'
import { Sidebar } from 'Containers/shared/sidebar'
import { TablePagination } from 'Containers/shared/tablePagination'
import { useHasPermission } from 'Hooks/useHasPermission'
import { DownloadFile } from 'Containers/shared/downloadfile'
import {
  Table,
  TableFooter,
  TableRowStatus,
  TableFooterText,
} from 'Components/shared/table'
import {
  Body,
  BodyTitle,
  BodyHeader,
  BodyContent,
} from 'Components/shared/body'
import { Loading } from 'Utils/loading'
import { usePagination } from 'Hooks/usePagination'
import { useToggle } from 'Hooks/useToggle'
import { useGetAudienceTypesQuery } from 'Services/global/audienceTypes'
import { FileStatus, useGetFilesQuery } from 'Services/files/files'
import { buildFilterQuery } from 'Filter/buildFilterQuery'
import type { RootState } from 'Utils/store'
import { formatFileDate } from 'Utils/dateUtils'
import { AudienceTypeHelper } from 'Types/audienceType'
import { ContractSource } from 'Types/index'

export const Files = () => {
  const dispatch = useDispatch()
  const { ipedId } = useParams()
  const { currentPage, handlePageChange, setCurrentPage } = usePagination()
  const [sortOrder, setSortOrder] = useState('desc')
  const [sortBy, setSortBy] = useState('delivered_date')
  const [columnData, setColumnData] = useState([
    {
      columnName: 'Delivered Date',
      columnWidth: 115,
      sortBy: 'delivered_date',
      sortOrder: sortOrder,
    },
    { columnName: 'File Name', columnWidth: 250 },
    { columnName: 'Audience Type', columnWidth: 120 },
    { columnName: 'Status', columnWidth: 120 },
    { columnName: 'Leads in File', columnWidth: 70},
    { columnName: 'Downloaded', columnWidth: 115 },
    { columnName: '', columnWidth: 60 },
  ])
  const { hasPermission: canSwitchInstitution } = useHasPermission(
    Permissions.SwitchInstitution
  )
  const [showSidebar, setShowSidebar] = useToggle(false)

  useEffect(() => {
    dispatch(setCandidatesContract())
  }, [])

  const filesFilter = useSelector(
    (state: RootState) => state.candidatesFilesFilter,
    shallowEqual
  )

  const encodedResult = buildFilterQuery(filesFilter)

  const { data: recordsInfo, isLoading } = useGetFilesQuery({
    ipedId,
    page: currentPage,
    order: sortOrder,
    filter: encodedResult,
  })
  const { data: audienceTypes, isFetching: isFetchingAudienceTypes } =
    useGetAudienceTypesQuery(ContractSource.candidates)

  const resetFilters = () => {
    dispatch({
      type: 'candidatesFilesFilter/reset',
    })
  }

  const flattenRecords = useMemo(() => {
    let rows = []
    if (recordsInfo) {
      const records = canSwitchInstitution ? recordsInfo.records : recordsInfo.records?.filter((record: any) => record.status !== FileStatus.failed && record.status !== FileStatus.undelivered)
      records?.forEach((record: any) => {
        rows.push([
          record.id,
          formatFileDate(record.delivered_date),
          record.file_name_label,
          AudienceTypeHelper.getReadableString(
            audienceTypes,
            record.audience_type
          ),
          <TableRowStatus status={record.status?.replaceAll('_', ' ')} />,
          record.leads_in_file,
          record.last_download_date
            ? formatFileDate(record.last_download_date)
            : '',
          record.status === FileStatus.undelivered ? <></> : <DownloadFile instId={ipedId} contractDeliveryId={record.id} />,
        ])
      })
    }
    return rows
  }, [audienceTypes, recordsInfo])

  useEffect(
    () =>
      setColumnData(
        columnData.map((column) => {
          if (column.sortBy === sortBy) {
            return {
              ...column,
              sortOrder: sortOrder,
              sortBy: sortBy,
            }
          }
          return column
        })
      ),
    [sortBy, sortOrder]
  )

  useEffect(() => {
    setCurrentPage(0)
  }, [encodedResult])

  if (isLoading || isFetchingAudienceTypes) return <Loading />
  return (
    <Body>
      <BodyHeader>
        <BodyTitle>Candidate Files</BodyTitle>
      </BodyHeader>
      <Sidebar
        sidebarTitle={['Filters']}
        sidebarWidth={309}
        header={<FilesFilterHeader resetFilters={resetFilters} />}
        showSidebar={showSidebar}
        setShowSideBar={setShowSidebar}
        handleCloseSidebar={setShowSidebar}
      >
        <CandidatesFilesFilter />
      </Sidebar>
      <BodyContent>
        {recordsInfo?.total_records > 0 && (
          <>
            <Table
              columnData={columnData}
              rows={flattenRecords}
              setSortOrder={setSortOrder}
              setSortBy={setSortBy}
            />

            <TableFooter>
              {recordsInfo.total_pages > 1 && (
                <TablePagination
                  rowsPerPage={10}
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                  totalRecords={recordsInfo.total_records}
                  totalPages={recordsInfo.total_pages}
                />
              )}
              <TableFooterText />
            </TableFooter>
          </>
        )}
      </BodyContent>
    </Body>
  )
}
