import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { FilterHeader } from 'Components/shared/segmentfilters'
import { Verification } from 'Containers/shared/segmentfilters/timebased/verification'
import { Demographics } from 'Containers/shared/segmentfilters/timebased/demographics'
import { ProspectProfile } from 'Containers/shared/segmentfilters/timebased/prospectprofile'
import { SingleSelectDropdown } from 'Components/shared/singleselectdropdown'
import { SidebarField } from 'Components/shared/sidebar'
import { buildFilterQuery } from 'Filter/buildFilterQuery'
import { Loading } from 'Utils/loading'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import type { RootState } from 'Utils/store'
import {
  AudienceTypeHelper,
  ContractSource,
  ContractType,
  WebSocketMethods,
} from 'Types/index'
import { useSocket } from 'Hooks/useSocket'
import { useGetAudienceTypesQuery } from 'Services/global/audienceTypes'
import { messageSent } from 'Slices/websocketSlice'
import { Contract } from 'Services/contracts/contractsUtils'
import { excludeKeys } from 'Containers/shared/segmentfilters/segmentUtils'
import { selectGeography } from 'Containers/shared/segmentfilters/timebased/demographics/GeographySlice'
import { useGetContractConfigQuery } from 'Services/global/contractConfig'


export const TimeBasedExploreSegmentsFilter = () => {
  const dispatch = useDispatch()
  const { ipedId } = useParams()
  const [resetKey, setResetKey] = useState(Date.now())
  const { waitingForResponse: isFetchingStudentsAvailable, availableStudents } = useSelector(
    (state: RootState) => state.websocketStatus
  )
  const { sendMessage } = useSocket()
  const { data: audienceTypes, isFetching: isFetchingAudienceTypes } =
    useGetAudienceTypesQuery(ContractSource.inquiries)
  const geography = useSelector(selectGeography)

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const timeBasedExploreSegmentsFilter = useSelector(
    (state: RootState) => state.timeBasedFilter
  )

  const [currentAudienceType, setCurrentAudienceType] = useState(
    AudienceTypeHelper.getNone()
  )

  const { data: contractConfig } = useGetContractConfigQuery(
    {
      contractSource: ContractSource.inquiries,
      contractType: ContractType.annual,
      audienceType: currentAudienceType,
    },
    { skip: !currentAudienceType }
  )

  const resetFilters = () => {
    dispatch({
      type: 'timeBasedFilter/reset',
    })
    setResetKey(Date.now())
  }

  const getAvailableStudents = () => {
    const message = {
      method: WebSocketMethods.students_available_count,
      data: {
        institution_id: ipedId,
        contract_source: ContractSource.inquiries,
        audience_type: currentAudienceType,
        filter: buildFilterQuery(
          timeBasedExploreSegmentsFilter,
          excludeKeys(ContractSource.inquiries, currentAudienceType),
          false
        ),
        max_look_back_period:
        (contractConfig?.available_lookback_periods &&
         contractConfig.available_lookback_periods[contractConfig.default_lookback_period]) ||
        719,      
      },
      override_sources: ['inquiries']

    }
    if (geography.zipFile.id) {
      message.data['zip_id'] = geography.zipFile.id
      message.data['zip_filter_exclusive'] = false
    }
    sendMessage(JSON.stringify(message))
    dispatch(messageSent())
  }

  let chosenItem: Partial<Contract> = undefined
  if (!AudienceTypeHelper.isNone(currentAudienceType))
    chosenItem = {
      id: '123-' + currentAudienceType,
      audience_type: currentAudienceType,
    }

  const studentsCount = availableStudents?.hasOwnProperty('total_count') ? availableStudents.total_count : availableStudents

  return (
    <>
      {(isFetchingStudentsAvailable || isFetchingAudienceTypes) && <Loading />}
      <div
        style={{
          width: '396px',
        }}
      >
        <SidebarField id='select-inquiries-type' label='Audience Type'>
          <SingleSelectDropdown
            id='select-audience-type'
            name='inquiriesType'
            value={
              currentAudienceType
                ? AudienceTypeHelper.getReadableString(
                    audienceTypes,
                    currentAudienceType
                  )
                : ''
            }
            style={matches ? { width: '229px' } : {}}
            values={AudienceTypeHelper.getAudienceTypeList(audienceTypes)}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              const chosenItem = AudienceTypeHelper.getAudienceType(
                audienceTypes,
                e.target.value
              )
              setResetKey(Date.now())
              if (chosenItem) {
                setCurrentAudienceType(chosenItem)
              }
            }}
          />
        </SidebarField>
        <FilterHeader
          availableStudents={studentsCount || 0}
          isFetchingStudentsAvailable={isFetchingStudentsAvailable}
          resetFilters={resetFilters}
          disableHeader={currentAudienceType ? false : true}
          getAvailableStudents={getAvailableStudents}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            marginBottom: '100px',
          }}
        >
          <Demographics chosenItem={chosenItem} resetKey={resetKey} />
          <ProspectProfile chosenItem={chosenItem} resetKey={resetKey} />
          <Verification chosenItem={chosenItem} />
        </div>
      </div>
    </>
  )
}
