import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FilterBy, CombineBy, properties } from 'Filter/properties'

interface MultiSelectState {
  /* selectedValues are the values currently selected in the dropdown */
  selectedValues: string[]
  /* values is the list of values that are available in the dropdown */
  values: string[]
  /* filterQuery is the query that is used to filter the data in the backend */
  filterQuery: any
}

type NullFilterType = {
  column: string
  op: FilterBy
  value_macro: string
  value?: any
  meta: {section: string, label: string}
}

type extraReducerType = {
  extraReducers?: any
}

export const multiSelectSlice = (
  column: string,
  action: string,
  values: string[],
  meta: { section: string; label: string, defaultValue?: string[], nullLabel?: string },
  transformValues?: Function,
  transformState: Function = function (selectedValues) {
    return selectedValues.map((value) =>
      value === "" ? 'None Selected' : value
    )
  },
  extraReducer?: Function,
) => {
  const potentialExtraReducers:extraReducerType = {}
  if (extraReducer) {
    potentialExtraReducers.extraReducers = extraReducer
  }
  return createSlice({
    name: column,
    initialState: {
      values,
      selectedValues: meta?.defaultValue ? meta.defaultValue : [],
      filterQuery: null,
    } as MultiSelectState,
    reducers: {
      [action]: (state, action: PayloadAction<string[]>) => {
        const payloadFormattedForAny = (action.payload.includes('Any') && !state.selectedValues.includes('Any')) ? ['Any'] : action.payload.filter(value => value !== 'Any')
        state.selectedValues = transformState ? transformState(payloadFormattedForAny) : payloadFormattedForAny
        let combinedFilterQuery
        let filterQuery =  payloadFormattedForAny.length === 0 || payloadFormattedForAny?.includes('Any')
        ? null
        : {
            column,
            op: FilterBy.IN,
            value:
              typeof transformValues == 'function'
                ? transformValues(state.selectedValues)
                : state.selectedValues,
            meta: {section: meta.section, label: meta.label},
          } 
          if(state.selectedValues.includes(meta?.nullLabel)) {
            const nullFilter:NullFilterType = {column, op: FilterBy.NULL, meta: filterQuery.meta, value_macro: 'NO_OP'}
            if(state.selectedValues.length > 1) {
              combinedFilterQuery = properties([filterQuery, nullFilter], CombineBy.OR)
            } else {
              combinedFilterQuery = nullFilter
            }
          } else {
            combinedFilterQuery = filterQuery
          }
        state.filterQuery = combinedFilterQuery
      },
    },
    ...potentialExtraReducers
  })
}
