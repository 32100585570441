import { Contract } from "Services/contracts/contractsUtils"
import { ContractConfigurationState } from "./ContractConfigurationSlice"
import { formatContractDate, formatDate } from "Utils/dateUtils"
import { ContractStatus } from "Types/contractTypes"


export const convertContractToContractConfiguration = (
  contract: Contract
): ContractConfigurationState => {
  return {
    contractId: contract.id,
    contractName: contract.label,
    contractType: contract.contract_type,
    contractSource: contract.source,
    contractInstitutionId: contract.ipedId || contract.institution_id,
    status: contract.status,
    purchaseType: contract.purchase_type,
    audienceType: contract.audience_type,
    salesforceID: contract.salesforce_id,
    startDate: formatDate(contract.start_date),
    endDate: formatDate(contract.end_date),
    leadCap: contract.lead_cap,
    notes: contract.notes,
    selectedFields: contract.fields_to_export,
    selectedDeliveryCadence: contract.delivery_cadence,
    selectedDeliveryModel: contract.delivery_model,
    selectedLookbackPeriod: contract.look_back_period,
    createdAt: contract.created_at,
    updatedAt: contract.updated_at || contract.created_at,
    isDisabledContract: [
      ContractStatus.expired,
      ContractStatus.completed,
    ].includes(contract.status),
    isClean: true,
    selectedCap: contract.delivery_quota_cap,
    manual_segment_order: contract.manual_segment_order,
    segment_fill_type: contract.segment_fill_type
  } as ContractConfigurationState
}
export const convertContractConfigurationToContract = (
  contractConfig: ContractConfigurationState
): Contract => {
  return {
    id: contractConfig.contractId,
    label: contractConfig.contractName,
    contract_type: contractConfig.contractType,
    source: contractConfig.contractSource,
    institution_id: contractConfig.contractInstitutionId,
    ipedId: contractConfig.contractInstitutionId,
    purchase_type: contractConfig.purchaseType,
    audience_type: contractConfig.audienceType,
    salesforce_id: contractConfig.salesforceID,
    start_date: formatContractDate(contractConfig.startDate),
    end_date: formatContractDate(contractConfig.endDate),
    lead_cap: contractConfig.leadCap,
    notes: contractConfig.notes,
    fields_to_export: contractConfig.selectedFields,
    delivery_cadence: contractConfig.selectedDeliveryCadence,
    delivery_model: contractConfig.selectedDeliveryModel,
    delivery_quota_cap: parseInt(contractConfig.selectedCap + ''),
    look_back_period: contractConfig.selectedLookbackPeriod,
    manual_segment_order: contractConfig.manual_segment_order,
    segment_fill_type: contractConfig.segment_fill_type,
  } as Contract
}
